import { atom } from "jotai";

type UserNameType = {
  name: string;
};

export interface Signer {
  external_id: string;
  sign_url: string;
  token: string;
  status: string;
  name: string;
  lock_name: boolean;
  email: string;
  lock_email: boolean;
  hide_email: boolean;
  blank_email: boolean;
  phone_country: string;
  phone_number: string;
  lock_phone: boolean;
  hide_phone: boolean;
  blank_phone: boolean;
  times_viewed: number;
  last_view_at: string | null;
  signed_at: string | null;
  auth_mode: string;
  qualification: string;
  require_selfie_photo: boolean;
  require_document_photo: boolean;
  geo_latitude: number | null;
  geo_longitude: number | null;
  redirect_link: string;
  signature_image: string | null;
  visto_image: string | null;
  document_photo_url: string;
  document_verse_photo_url: string;
  selfie_photo_url: string;
  selfie_photo_url2: string;
  send_via: string;
  resend_attempts: number | null;
  cpf: string;
  cnpj: string;
  send_automatic_whatsapp_signed_file: boolean | null;
  liveness_photo_url: string;
  selfie_validation_type: string;
  ip: string | null;
}

export const signersAtom = atom<Signer[]>([]);

export const userNameAtom = atom<UserNameType>({ name: "" });

export const profilePicModal = atom(false);
