// src/form-component/FormInputText.tsx
import { Controller } from "react-hook-form";
import styles from "./InputForm.module.css";
import { Box, Stack } from "@mui/material";
import FormLabel from "./FormLabel";
import { useState } from "react";

type Props = {
  name: string;
  control: any;
  label?: string;
  smLabel?: boolean;
  required?: boolean;
  defaultValue?: string;
  integers?: boolean;
  placeholder?: string;
  setIsParentFocused?: any;
  info?: string[] | "";
  error?: string;
};

const FormNumber: React.FC<Props> = ({
  name,
  control,
  label,
  smLabel,
  required,
  defaultValue,
  integers,
  placeholder,
  info,
  setIsParentFocused,
  error,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = () => {
    setIsFocused(true);
    setIsParentFocused && setIsParentFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
    setIsParentFocused && setIsParentFocused(false);
  };

  return (
    <>
      <FormLabel
        required={required}
        label={label}
        smLabel={smLabel}
        info={info}
        isFocused={isFocused}
      />
      <Box className={styles.wrapper}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: required || false,
              message: "Por favor, complete este campo",
            },
          }}
          defaultValue={defaultValue}
          render={({
            field: { onChange, value },
            fieldState: { error: fieldError },
          }) => (
            <Stack width={"100%"} textAlign={"left"}>
              <input
                value={value}
                className={styles.input}
                maxLength={150}
                placeholder={placeholder}
                onChange={(e) => {
                  let valueNormalized =
                    typeof e.target.value === "string"
                      ? e.target.value.split("")
                      : [];
                  let newValue = valueNormalized
                    .filter((val) => /[\d|\\,.]/.test(val))
                    .join("");

                  if (integers) {
                    newValue = valueNormalized
                      .filter((val) => /[\d|\\]/.test(val))
                      .join("");
                  }
                  onChange(newValue);
                }}
                defaultValue={value}
                onFocus={onFocus}
                onBlur={onBlur}
              />
              {(fieldError || error) && (
                <Box className="red" mt={-2} mb={2} ml={1}>
                  {fieldError?.message || error}
                </Box>
              )}
            </Stack>
          )}
        />
      </Box>
    </>
  );
};

export default FormNumber;
