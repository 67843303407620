import { AlignmentType, Footer, Header, ImageRun, Paragraph } from "docx";

import { mariana_rebora_header } from "../docImages/mariana-rebora";

export const styles_mariana_rebora = {
  companyId: ["11162"],
  companyName: "Mariana Rebora",
  font: "Palatino Linotype",
  titleSize: 22,
  textSize: 22,
  lineSpacing: 380,
  marginRight: 1730,
  marginLeft: 1730,
  beforeParagraph: 0,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 200,
          after: 200,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: mariana_rebora_header,
            transformation: {
              width: 400,
              height: 100,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [],
  }),
};
