import React, { useEffect, useState, ComponentType } from "react";

import { setAppError, setLoading } from "../../Utils/modalMethods";
import { APIGetWithError } from "../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, useHistory } from "react-router-dom";
import { User } from "../../Types/User.types";
import CONSTANTS from "../../Common/constants";

interface Props {
  component: ComponentType;
  admittedRoles: string[];
  includeInternalUser?: boolean;
}

const RoleCheckedComponent: React.FC<Props> = ({
  component: Component,
  admittedRoles,
  includeInternalUser,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await APIGetWithError("/users/get-user-role", token);
        console.log(response);
        if (response.user) setUser(response.user);
        if (!response.user) {
          history.push(CONSTANTS.ROUTES.HOME);
          setAppError();
        }
      } catch (err) {
        history.push(CONSTANTS.ROUTES.HOME);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (!user) return <div>Cargando...</div>;

  if (includeInternalUser && user?.internalUser) return <Component />;

  if (admittedRoles.includes(user.role)) {
    return <Component />;
  }
  return <Redirect to={CONSTANTS.ROUTES.HOME} />;
};

export default RoleCheckedComponent;
