import {
  faSave,
  faSignature,
  faDownload,
  // faShareAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Image from "../../uiComponents/Image/Image";
import "./DocxToolbar.styles.css";
import { APIPostWithError } from "../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
// import { setConfirmationModalAtom } from "../../jotai/confimationModalAtom.jotai";
import { setCommonComponents } from "../../jotai/commonComponentAtom.jotai";

type Props = {
  document: any;
  onDownload: () => void;
  updateDocument: (bool: boolean) => void;
  sendToEsign: () => void;
};

const DocxToolbar: React.FC<Props> = ({
  document,
  onDownload,
  updateDocument,
  sendToEsign,
}) => {
  const [docName, setDocName] = useState<string>("");
  const { getAccessTokenSilently } = useAuth0();

  const { editedDocumentName } = useSelector(
    (state: RootState) => state.triggers
  );

  useEffect(() => {
    if (editedDocumentName) {
      setDocName(editedDocumentName);
    } else if (document.fileName) {
      setDocName(document.fileName);
    }
  }, [document, editedDocumentName]);

  const onConfirmShareDocument = async () => {
    const accessToken = await getAccessTokenSilently();
    const params = {
      documentId: document.documentId,
      filePath: document.filePath,
      fileName: document.fileName,
    };
    const data = await APIPostWithError("/doc-ref/share", accessToken, params);
    if (!data) return;
    setCommonComponents({
      showSuccessModal: true,
      message: "El documento ha sido compartido con éxito",
      subMessage:
        "El documento ha sido compra  con éxito, puedes compartir el link con quien desees",
      disclaimer: "*El link es valido por 7 dias",
    });
  };

  // const handleClickShareDocument = () => {
  //   setConfirmationModalAtom({
  //     onConfirm: onConfirmShareDocument,
  //     message: "Compartir documento a clientes",
  //     subMessage:
  //       "Si procede, se generará un enlace público para que cualquiera que lo posea pueda ver y editar el documento. Se recomienda compartir el enlace únicamente con las partes involucradas.",
  //     disclaimer: "El link será publico por 7 días",
  //     show: true,
  //   });
  // };

  return (
    <div className="docx-toolbar">
      <div className="docx-toolbar-title">
        <Image name="word-icon.png" className="docx-toolbar-word" />
        <div className="docx-toolbar-name">{docName}</div>
        <div className="docx-toolbar-extension">Word</div>
      </div>
      <div className="docx-toolbar-buttons">
        <button onClick={() => updateDocument(false)}>
          <FontAwesomeIcon icon={faSave} className="docx-toolbar-icon" />
          Guardar
        </button>
        <button onClick={sendToEsign}>
          <FontAwesomeIcon icon={faSignature} className="docx-toolbar-icon" />
          Firmar
        </button>
        <button onClick={onDownload}>
          <FontAwesomeIcon icon={faDownload} className="docx-toolbar-icon" />
          Descargar
        </button>
        {/* <button onClick={handleClickShareDocument}>
          <FontAwesomeIcon icon={faShareAlt} className="docx-toolbar-icon" />
          Compartir a Clientes
        </button> */}
      </div>
    </div>
  );
};

export default DocxToolbar;
