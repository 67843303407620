import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  APIGetWithError,
  APIPostWithError,
} from "../../../Services/authenticated";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { categories } from "../../../Common/constants";

const schema = z.object({
  documentName: z
    .string()
    .min(3, "El nombre del documento debe tener al menos 3 caracteres")
    .max(50, "El nombre del documento no puede tener más de 50 caracteres")
    .regex(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s]+$/,
      "El nombre del documento solo puede contener letras, números y espacios"
    ),
  documentName1: z
    .string()
    .min(3, "La descripción del documento debe tener al menos 3 caracteres")
    .max(50, "La descripción del documento no puede tener más de 50 caracteres")
    .regex(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s]+$/,
      "El nombre del documento solo puede contener letras, números y espacios"
    ),
  documentType: z
    .string()
    .min(3, "El tipo de documento debe tener al menos 3 caracteres")
    .max(50, "El tipo de documento no puede tener más de 50 caracteres")
    .refine(
      (val) => categories.some((category) => category.value === val),
      "Tipo de documento no válido"
    ),
  companyIdToUpload: z
    .string()
    .regex(/^\d{5}$/, "La empresa seleccionada no existe"),
  removeLogo: z.boolean(),
});

type FormData = z.infer<typeof schema>;

const CompanyDocumentUploader = () => {
  const [companies, setCompanies] = useState([]);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: zodResolver(schema) });

  const getAllCompanies = async () => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const allCompanies = await APIGetWithError(
        "/companies/get-all-companies",
        accesToken
      );

      if (allCompanies && allCompanies.length > 0) {
        setCompanies(allCompanies);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields: Field[] = [
    {
      name: "documentName",
      label: "Nombre del Documento",
      type: "text",
      required: true,
    },
    {
      name: "documentName1",
      label: "Descripción",
      type: "text",
      required: true,
    },
    {
      name: "documentType",
      label: "Tipo de documento",
      type: "options",
      required: true,
      options: categories?.map(
        (category: { value: string; label: string }) => ({
          value: category.value,
          label: category.label,
        })
      ),
    },
    {
      name: "companyIdToUpload",
      label: "Seleccione la company a la cual subirá el documento",
      type: "options",
      required: true,
      options: companies.map((com: any) => ({
        label: com.companyName,
        value: com.companyId,
      })),
    },
    {
      name: "removeLogo",
      label: "Desea quitar el logo?",
      type: "options",
      options: [
        {
          value: true,
          label: "Si",
        },
        {
          value: false,
          label: "No",
        },
      ],
    },
  ];

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);

      const finalQuestionData = JSON.parse(
        localStorage.getItem("generator-form-data") || "[]"
      );

      const finalDocumentData = JSON.parse(
        localStorage.getItem("generator-form-document") || "[]"
      );

      const finalSectionData = JSON.parse(
        localStorage.getItem("generator-form-sections") || "{}"
      );

      if (
        finalQuestionData.length < 1 ||
        finalDocumentData.length < 1 ||
        Object.keys(finalSectionData).length < 1
      ) {
        setAppError(
          "Faltan datos para cargar el documento",
          "Debe haber tanto preguntas como documentos"
        );
        return;
      }

      const now = Date.now();

      const {
        documentName,
        documentName1,
        documentType,
        companyIdToUpload,
        removeLogo,
      } = data;

      const currentCompany: any = companies.find(
        (com: any) => com.companyId === companyIdToUpload
      );

      if (!currentCompany) {
        setAppError("No se encontró la empresa seleccionada");
        return;
      }

      const params = {
        documentName,
        documentName1,
        documentName2: documentName1,
        questionData: finalQuestionData,
        documentData: finalDocumentData,
        sectionData: finalSectionData,
        date: now.toString(),
        companyIdToUpload,
        companyName: currentCompany?.companyName,
        removeLogo,
        generatorVersion: "2.0",
        dataPath: finalDocumentData.dataPath,
        documentType,
      };

      const accesToken = await getAccessTokenSilently();

      const uploadData = await APIPostWithError(
        "/company-docs/create-company-document",
        accesToken,
        params
      );

      if (uploadData.saved) {
        setCommonComponents({
          showSuccessModal: true,
          message: "Guardado exitoso",
          subMessage: "El documento se guardó correctamente.",
          disclaimer: "Puede cerrar esta pestaña",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack alignItems="center" width="100vw" pt={4} mb={5}>
      <h2>Cargar documento nuevo</h2>
      <Box mt={2}>* Recordá revisar las preguntas y el documento</Box>
      {isAuthenticated && (
        <Stack
          width={800}
          maxWidth={"90vw"}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            p: 4,
            my: 4,
            borderRadius: 1,
          }}
        >
          <AutomaticForm control={control} fields={fields} errors={errors} />
          <Button
            onClick={() => handleSubmit(onSubmit)()}
            sx={{ mt: 2 }}
            variant="contained"
          >
            Cargar
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyDocumentUploader;
