import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostFile } from "../../../Services/authenticated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faCheckCircle,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useDropzone } from "react-dropzone";

import "./styles.css";
import { insertDocumentToAtom } from "../../../jotai/documents.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { resetCommonComponent } from "../../../jotai/commonComponentAtom.jotai";

type AcceptedFileTypes =
  | "doc"
  | "docx"
  | "pdf"
  | "xls"
  | "xlsx"
  | "png"
  | "jpg"
  | "jpeg"
  | "heic"
  | "ppt"
  | "pptx";

type File = {
  lastModified: number;
  lastModifiedDate?: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

const FileUploadModal: React.FC = () => {
  const [files, setFiles] = useState<File[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  const getExtension = (filename: string) => {
    if (!filename || filename.length <= 2) {
      return "";
    }
    const position = filename.lastIndexOf(".");
    return position === -1 ? "" : filename.slice(position + 1).toLowerCase();
  };

  const getOnlyName = (filename: string) => {
    const extension = `.${getExtension(filename)}`;
    const finalName = filename.replace(extension, "");
    return finalName;
  };

  const isValidFileSize = (file: File) => {
    if (file && file.size && file.size < 40000636) {
      return true;
    }
    setAppError(
      "Archivo demasiado grande",
      "El maximo permitido por archivo es de 5MB"
    );
    return false;
  };

  const isValidName = (file: File) => {
    if (file && file.name && file.name.length < 80) {
      return true;
    }
    setAppError(
      "Nombre demasiado largo",
      "Por favor suba un archivo con un nombre de no más de 80 caracteres"
    );
    return false;
  };

  const isValidExtension = (file: File): boolean => {
    const extension = getExtension(file.name);
    const validExtensions: AcceptedFileTypes[] = [
      "doc",
      "docx",
      "pdf",
      "xls",
      "xlsx",
      "png",
      "jpg",
      "jpeg",
      "heic",
      "ppt",
      "pptx",
    ];

    if (validExtensions.includes(extension as AcceptedFileTypes)) {
      return true;
    }
    setAppError(
      "Extensión de archivo no permitida",
      "Solo se permiten archivos tipo xls, xlsx, pptx, pdf, doc, docx, png, jpg y jpeg."
    );
    return false;
  };

  const onChangeFile = (
    fileChangeEvent: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (fileChangeEvent.target.files && fileChangeEvent.target.files[0]) {
      const file = fileChangeEvent.target.files[0];

      if (
        isValidExtension(file) &&
        isValidFileSize(file) &&
        isValidName(file)
      ) {
        setFiles([file]);
      }
    }
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles[0]) {
      const file = acceptedFiles[0];

      if (
        isValidExtension(file) &&
        isValidFileSize(file) &&
        isValidName(file)
      ) {
        setFiles([file]);
      }
    }
  };

  const getFormat = (file: File): string | null => {
    const extension = getExtension(file.name);
    if (extension === "docx") {
      return "word";
    }
    if (extension === "doc") {
      return "doc";
    }
    if (extension === "pdf") {
      return "pdf";
    }
    if (extension === "xls") {
      return "xls";
    }
    if (extension === "xlsx") {
      return "xlsx";
    }
    if (extension === "jpg") {
      return "jpg";
    }
    if (extension === "jpeg") {
      return "jpeg";
    }
    if (extension === "png") {
      return "png";
    }
    if (extension === "heic") {
      return "heic";
    }
    if (extension === "ppt") {
      return "ppt";
    }
    if (extension === "pptx") {
      return "pptx";
    }

    return null;
  };

  const getDocType = (file: File): string | null => {
    const extension = getExtension(file.name);
    if (extension === "docx" || extension === "doc") {
      return "Word";
    }
    if (extension === "pdf") {
      return "PDF";
    }
    if (extension === "xls") {
      return "Excel";
    }
    if (extension === "xlsx") {
      return "Excel";
    }
    if (extension === "ppt" || extension === "pptx") {
      return "PPT";
    }
    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "heic"
    ) {
      return "Imagen";
    }

    return null;
  };

  const onSaveDocument = async () => {
    try {
      setLoading(true);
      if (files[0] && files[0].name) {
        const now = Date.now();

        const fileFullName = `${getOnlyName(
          files[0].name
        )}_${now}.${getExtension(files[0].name)}`;

        const format = getFormat(files[0]);
        const docType = getDocType(files[0]);
        const accesToken = await getAccessTokenSilently();

        const docRef = await APIPostFile(
          "/doc-ref/create-doc-ref",
          accesToken,
          {
            data: files[0],
            docName: fileFullName,
            fileNameNoExtension: getOnlyName(files[0].name),
            documentType: docType,
            versionId: now,
            versionNumber: 1,
            date: now,
            size: files[0].size,
            updateDate: now,
            format,
            documentKind: "Sin definir",
          }
        );

        if (docRef.documentSaved && docRef.document) {
          insertDocumentToAtom(docRef.document);
          resetCommonComponent();
        } else {
          setAppError();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <AndesModal
      message="Subir nuevo documento"
      submitButtonText="Subir documento"
      handleClose={resetCommonComponent}
      {...(files[0] && { handleSubmit: onSaveDocument })}
    >
      <div className="upload-modal-body">
        <div>
          <label
            htmlFor="upload-file"
            className="upload-modal-select-button b-primary b-primary-hover center"
          >
            <FontAwesomeIcon
              icon={faFolderPlus}
              className="upload-modal-button-icon"
            />
            Seleccionar Documento
          </label>
          <input
            type="file"
            onChange={onChangeFile}
            className="upload-modal-file-input"
            id="upload-file"
          />
          <div {...getRootProps()} className="upload-modal-drop-zone">
            <input {...getInputProps()} />
            <p className="margin-v primary">
              {!files[0]
                ? "Arrastre su archivo aquí (1 archivo)"
                : "Archivo seleccionado:"}
            </p>
            {files[0] && (
              <div>
                {getOnlyName(files[0].name)}{" "}
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="green icon-margin-l"
                />
              </div>
            )}
            {!files[0] && <div>Ningún archivo seleccionado</div>}
            <FontAwesomeIcon
              icon={faFileUpload}
              className={`upload-modal-file-background ${
                !files[0] ? "secondary" : "green"
              }`}
            />
            <div className="upload-modal-file-types primary">
              *Podés cargar archivos de tipo Word, PDF, Planillas de Excel ó
              imagenes JPG o PNG
            </div>
          </div>
        </div>
      </div>
    </AndesModal>
  );
};

export default FileUploadModal;
