import React, { useEffect } from "react";
import AllSignaturesTable from "../../../../components/Tables/AllSignatures/AllSignaturesTable";
import { useAtomValue } from "jotai";
import { electronicSignaturesAtom } from "../../../../jotai/electronicSignatures.jotai";

type Props = {
  getSignatures: () => void;
};

const AllSignaturesPage: React.FC<Props> = ({ getSignatures }) => {
  const { electronicSignatures } = useAtomValue(electronicSignaturesAtom);

  useEffect(() => {
    if (electronicSignatures.length < 1) getSignatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {electronicSignatures && (
        <AllSignaturesTable eSignatures={electronicSignatures} />
      )}
    </>
  );
};

export default AllSignaturesPage;
