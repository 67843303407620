import React from "react";

import "./styles.css";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeErrorModal } from "../../../Utils/modalMethods";
import { colorPallette } from "../../../Common/theme";
import { useAtomValue } from "jotai";
import { commonComponentAtom } from "../../../jotai/commonComponentAtom.jotai";

const ErrorModal: React.FC<any> = () => {
  const { message, subMessage, disclaimer } = useAtomValue(commonComponentAtom);

  return (
    <Modal open>
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 9999,
          backgroundColor: "white",
          width: 600,
          borderRadius: 1,
          padding: 3,
          paddingTop: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={closeErrorModal}
          sx={{
            fontSize: "1.4rem",
            color: colorPallette.lightRed,
            position: "absolute",
            top: 23,
            right: 8,
            "&:hover": {
              color: "rgb(107, 107, 158)",
            },
          }}
          endIcon={<FontAwesomeIcon icon={faTimes} />}
        ></Button>

        <Typography
          variant="h5"
          sx={{
            pt: 2,
            fontWeight: "bold",
            textAlign: "left",
            fontSize: "1.3rem",
            width: "100%",
            color: colorPallette.lightRed,
            paddingRight: "40px",
          }}
        >
          {message || "Error"}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            margin: "20px",
            textAlign: "left",
            width: "100%",
            color: "gray",
          }}
        >
          {subMessage || "Ha habido un error de sistema. Intentelo más tarde."}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: colorPallette.lightRed,
            fontSize: "0.8rem",
            textAlign: "left",
            width: "100%",
          }}
        >
          {disclaimer ||
            "Si tiene un problema persistente, contacte con soporte."}
        </Typography>

        <Stack
          direction="row"
          sx={{
            mt: 4,
            width: "100%",
            justifyContent: "flex-end",
            padding: 0,
            "& button": {
              minWidth: "100px",
              height: 40,
            },
          }}
        >
          <Button
            sx={{ bgcolor: colorPallette.lightRed, color: colorPallette.white }}
            variant="contained"
            onClick={closeErrorModal}
          >
            {"Cerrar"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
