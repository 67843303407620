import { Controller } from "react-hook-form";
import styles from "./InputForm.module.css";
import { Box, FormHelperText, Stack } from "@mui/material";
import { useState } from "react";
import FormLabel from "./FormLabel";

type Props = {
  name: string;
  control: any;
  label?: string;
  smLabel?: boolean;
  required?: boolean;
  defaultValue?: string | null;
  width?: string;
  info?: string[] | "";
  maxLength?: number;
  rules?: any;
  error?: string;
};

const FormInput: React.FC<Props> = ({
  name,
  control,
  label,
  smLabel,
  required,
  defaultValue,
  width,
  info,
  maxLength,
  rules,
  error,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const customRules = rules || {};

  return (
    <>
      <FormLabel
        required={required}
        label={label}
        smLabel={smLabel}
        info={info}
        isFocused={isFocused}
      />
      <Box className={styles.wrapper}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: required || false,
              message: "Por favor, complete este campo",
            },
            ...customRules,
          }}
          defaultValue={defaultValue ?? ""}
          render={({
            field: { onChange, onBlur, value, ref },
            fieldState: { error: fieldError },
          }) => (
            <Stack width={width || "100%"} textAlign={"left"}>
              <input
                ref={ref}
                value={value || ""}
                className={styles.input}
                maxLength={maxLength || 150}
                onChange={onChange} // Se pasa correctamente
                onBlur={(e) => {
                  setIsFocused(false);
                  onBlur(); // Se pasa el onBlur de React Hook Form
                }}
                onFocus={() => setIsFocused(true)}
              />
              {(fieldError || error) && (
                <FormHelperText error sx={{ mt: -1, mb: 2, ml: 1 }}>
                  {fieldError?.message || error}
                </FormHelperText>
              )}
            </Stack>
          )}
        />
      </Box>
    </>
  );
};

export default FormInput;
