import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { useAtomValue, useSetAtom } from "jotai";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { userNameAtom } from "../../../jotai/atoms.jotai";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { APIPostWithError } from "../../../Services/authenticated";
import {
  CompanyUser,
  setAllCompanyUsers,
} from "../../../redux/slices/masterData";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../jotai/commonComponentAtom.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";

const EditProfileNameModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const { allCompanyUsers } = useSelector(
    (state: RootState) => state.masterData
  );
  const { currentUser } = useAtomValue(commonComponentAtom);

  const setUserName = useSetAtom(userNameAtom);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name: currentUser?.name },
  });

  const fields: Field[] = [
    {
      name: "name",
      label: "Nombre",
      type: "text",
      required: true,
    },
  ];

  const handleSave = async (data: any) => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const updatedName = await APIPostWithError(
        "/profile/update-profile-name",
        accesToken,
        data
      );

      if (updatedName.success) {
        setUserName(data);
        setLoading(false);
        const updatedCompanyUsers = allCompanyUsers.map((u: CompanyUser) => {
          if (u.userId === currentUser?.userId) {
            return { ...u, name: data.name };
          }
          return u;
        });
        dispatch(setAllCompanyUsers(updatedCompanyUsers));
        resetCommonComponent();
        setCommonComponents({
          showSuccessModal: true,
          message: "El nombre se actualizó",
        });
      } else {
        setAppError(updatedName.message || "Error al actualizar el nombre.");
      }
    } catch (error) {
      console.error(error);
      setAppError("Hubo un problema al actualizar el nombre.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AndesModal
      message="Escriba su nuevo nombre"
      handleSubmit={handleSubmit(handleSave)}
      handleClose={resetCommonComponent}
      submitButtonText="Guardar"
    >
      <Box mt={2} width="100%">
        <AutomaticForm fields={fields} control={control} errors={errors} />
      </Box>
    </AndesModal>
  );
};

export default EditProfileNameModal;
