import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import pattern from "../../Assets/svg/pattern.svg";
import { colorPallette, cardStyle } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBolt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import CONSTANTS from "../../Common/constants";

const benefits = [
  {
    title: "Ahorro de plata y tiempo",
    description:
      "Disminuí la carga operativa de tu empresa. Ganá tiempo y dinero. 59% de reducción del tiempo dedicado a la gestión de tus contratos.",
  },
  {
    title: "Evitá errores",
    description:
      "Evitá errores en la generación de documentos. 92% de ahorro de costes por contrato procesado.",
  },
  {
    title: "Personalizá los documentos",
    description: "Personalizá los documentos de tu empresa.",
  },
  {
    title: "Accedé desde donde estés",
    description:
      "Accedé desde donde estés a todos tus documentos guardados en la Nube.",
  },
];

const fadeStyle = {
  animation: "fadeInOut 3s infinite",
};

interface BenefitCardProps {
  title: string;
  description: string;
  number?: string;
}

const BenefitCard: React.FC<BenefitCardProps> = ({
  title,
  description,
  number,
}) => {
  const history = useHistory();
  return (
    <Card
      sx={{
        minWidth: 275,
        maxWidth: 560,
        width: "100%",
        margin: 2,
        minHeight: 200,
        position: "relative",
        transition: "all 0.3s",
        textAlign: "left",
        "&:hover": {
          transform: "scale(1.01) rotate(-0.2deg)",
          bgcolor: "#1a183b",
        },
        ...cardStyle.dark,
        // background:
        //   "linear-gradient(120deg, rgba(10,9,26,1) 0%, rgba(24,22,57,1) 90%, rgba(24,22,57,0.98) 100%)",
      }}
    >
      <CardContent>
        {/* <Box
          component="img"
          src={pattern}
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            width: "100%",
            position: "absolute",
            filter: "invert(1)",
            opacity: 0.4,
          }}
        /> */}
        {/* {number && (
          <Typography
            variant="h5"
            component="div"
            className="roboto"
            color={colorPallette.primary}
            fontWeight={700}
            fontSize={80}
          >
            {number}
          </Typography>
        )} */}
        <FontAwesomeIcon
          icon={faBolt}
          style={{ position: "absolute", right: 12, color: colorPallette.dark }}
        />
        <Typography fontSize={12} color={colorPallette.dark}>
          Andes Docs
        </Typography>
        <Typography
          variant="h5"
          component="div"
          className="roboto"
          fontWeight={700}
          mb={2}
          color={colorPallette.primary}
        >
          {title}
        </Typography>
        <Box
          sx={{
            height: 2,
            width: 70,
            bgcolor: colorPallette.secondary,
            transform: "translateY(-5px)",
            mb: 1,
          }}
        />
        <Typography variant="body2" color="white" mb={5}>
          {description}
        </Typography>
        <Button
          variant="outlined"
          onClick={() => {
            history.push(CONSTANTS.ROUTES.CONTACT);
          }}
        >
          Comenzar{" "}
          <FontAwesomeIcon
            icon={faArrowRight}
            style={{ marginLeft: 12, color: colorPallette.white, fontSize: 12 }}
          />
        </Button>
      </CardContent>
    </Card>
  );
};

const BenefitCards: React.FC = () => {
  const styles = `
  @keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
  }
  `;

  // Injecting the keyframes into the document
  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(styles, styleSheet.cssRules.length);

  return (
    <Box
      sx={{
        pt: 8,
        display: "flex",
        flexDirection: "column",
        minHeight: "900px",
        alignItems: "center",
        background:
          "linear-gradient(120deg, rgba(10,9,26,1) 0%, rgba(24,22,57,1) 90%, rgba(24,22,57,0.98) 100%)",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        fontWeight="bold"
        sx={{ width: "90%", maxWidth: "1200px" }}
        mb={3}
      >
        <FontAwesomeIcon
          icon={faBolt}
          style={{
            marginRight: "12px",
            fontSize: "1.3rem",
            transform: "translateY(-2px)",
            opacity: 0.8,
            color: colorPallette.primary,
            ...fadeStyle,
          }}
        />
        Escalá tu<span style={{ color: colorPallette.primary }}> Negocio</span>.
        Dedicate a lo{" "}
        <span style={{ color: colorPallette.primary }}> Importante</span>
        <FontAwesomeIcon
          icon={faBolt}
          style={{
            marginLeft: "12px",
            fontSize: "1.3rem",
            transform: "translateY(-2px)",
            color: colorPallette.primary,
            ...fadeStyle,
          }}
        />
      </Typography>
      <Typography
        fontSize="1.1rem"
        sx={{ width: "90%", maxWidth: "1200px" }}
        textAlign="center"
      >
        Andes Docs te acompaña en cada paso del proceso de creación de
        contratos. Desde la creación del borrador hasta la firma y distribución,
        Andes Docs te ayuda a optimizar tus procesos contractuales.
      </Typography>
      <Box
        sx={{
          width: "100%",

          //   "linear-gradient(270deg, rgba(168,187,253,1) 0%, rgba(246,249,252,1) 100%)",
          //   "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(168,187,253,1) 100%)",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 4,
          mt: 4,
        }}
      >
        <Box
          component="img"
          src={pattern}
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
            width: "100%",
            position: "absolute",
            filter: "invert(1)",
            opacity: 0.4,
            maxWidth: "1800px",
          }}
        />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{ maxWidth: "1200px" }}
        >
          {benefits.map((benefit, index) => (
            <Grid
              item
              xs={12}
              md={6}
              key={index}
              alignItems="center"
              display={"flex"}
              justifyContent={"center"}
            >
              <BenefitCard
                title={benefit.title}
                description={benefit.description}
                //   number={benefit.number}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default BenefitCards;
