import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../../Services/authenticated";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";
import { categories } from "../../../Common/constants";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  documentName: z
    .string()
    .min(3, "El nombre del documento debe tener al menos 3 caracteres")
    .max(50, "El nombre del documento no puede tener más de 50 caracteres")
    .regex(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s]+$/,
      "El nombre del documento solo puede contener letras, números y espacios"
    ),
  documentName1: z
    .string()
    .min(3, "La descripción del documento debe tener al menos 3 caracteres")
    .max(50, "La descripción del documento no puede tener más de 50 caracteres")
    .regex(
      /^[a-zA-ZáéíóúÁÉÍÓÚñÑ0-9\s]+$/,
      "El nombre del documento solo puede contener letras, números y espacios"
    ),
  documentType: z
    .string()
    .min(3, "El tipo de documento debe tener al menos 3 caracteres")
    .max(50, "El tipo de documento no puede tener más de 50 caracteres")
    .refine(
      (val) => categories.some((category) => category.value === val),
      "Tipo de documento no válido"
    ),
  removeLogo: z.boolean(),
});

type FormData = z.infer<typeof schema>;

const CompanyDocumentUploaderExternal = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: zodResolver(schema) });

  const fields: Field[] = [
    {
      name: "documentName",
      label: "Nombre del Documento",
      type: "text",
      required: true,
      validation: (value: string) => {
        if (!/^[a-zA-Z0-9\s]+$/.test(value)) {
          return "El nombre del documento contiene caracteres no permitidos";
        }
        if (value.length > 50) {
          return "El nombre del documento no debe exceder los 50 caracteres";
        }
        return true;
      },
    },
    {
      name: "documentName1",
      label: "Descripción",
      type: "text",
      required: true,
    },
    {
      name: "documentType",
      label: "Tipo de documento",
      type: "options",
      required: true,
      options: categories?.map(
        (category: { value: string; label: string }) => ({
          value: category.value,
          label: category.label,
        })
      ),
    },
    {
      name: "removeLogo",
      label: "¿Desea quitar el logo? (Remueve el encabezado y pie de página)",
      type: "options",
      options: [
        { value: true, label: "Si" },
        { value: false, label: "No" },
      ],
    },
  ];

  const onSubmit = async (data: any) => {
    console.log("Formulario enviado con datos:", data);
    try {
      setLoading(true);

      const finalQuestionData = JSON.parse(
        localStorage.getItem("generator-form-data") || "[]"
      );
      const finalDocumentData = JSON.parse(
        localStorage.getItem("generator-form-document") || "[]"
      );
      const finalSectionData = JSON.parse(
        localStorage.getItem("generator-form-sections") || "{}"
      );

      if (
        finalQuestionData.length < 1 ||
        finalDocumentData.length < 1 ||
        Object.keys(finalSectionData).length < 1
      ) {
        setAppError(
          "Faltan datos para cargar el documento",
          "Debe haber tanto preguntas como documentos"
        );
        return;
      }

      const now = Date.now();

      const { removeLogo, documentName, documentName1, documentType } = data;

      const params = {
        documentName,
        documentName1,
        documentName2: documentName1,
        questionData: finalQuestionData,
        documentData: finalDocumentData,
        sectionData: finalSectionData,
        date: now.toString(),
        removeLogo,
        generatorVersion: "2.0",
        dataPath: finalDocumentData.dataPath,
        documentType,
      };

      const accessToken = await getAccessTokenSilently();
      const uploadData = await APIPostWithError(
        "/company-docs/create-company-document",
        accessToken,
        params
      );

      if (uploadData.saved) {
        setCommonComponents({
          showSuccessModal: true,
          message: "Guardado exitoso",
          subMessage: "El documento se guardó correctamente.",
          disclaimer: "Puede cerrar esta pestaña",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack alignItems="center" width="100vw" pt={4} mb={5}>
      <h2>Cargar documento nuevo</h2>
      <Box mt={2}>* Recordá revisar las preguntas y el documento</Box>
      {isAuthenticated && (
        <Stack
          width={800}
          maxWidth="90vw"
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            p: 4,
            my: 4,
            borderRadius: 1,
          }}
        >
          <AutomaticForm control={control} fields={fields} errors={errors} />
          <Button
            onClick={handleSubmit(onSubmit)}
            sx={{ mt: 2 }}
            variant="contained"
            type="submit"
          >
            Cargar
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyDocumentUploaderExternal;
