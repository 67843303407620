import { useForm } from "react-hook-form";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { Box } from "@mui/material";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../jotai/commonComponentAtom.jotai";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { setLoading } from "../../../Utils/modalMethods";
import { APIPostWithError } from "../../../Services/authenticated";
import { updateDocumentOnAtom } from "../../../jotai/documents.jotai";
import { useAtomValue } from "jotai";

const StatusModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useSelector((state: RootState) => state.masterData);
  const { currentDocument } = useAtomValue(commonComponentAtom);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { status: currentDocument?.status || "unapproved" },
  });

  const handleToggle = async (internalStatus: string) => {
    const accessToken = await getAccessTokenSilently();
    const approvedByName = user?.name || "";

    const updatedDocument = {
      ...currentDocument,
      status: internalStatus,
      approvedBy: approvedByName,
    };

    try {
      setLoading(true);
      const res = await APIPostWithError(
        "/doc-ref/edit-document",
        accessToken,
        updatedDocument
      );
      if (res) {
        updateDocumentOnAtom(updatedDocument);
        setCommonComponents({
          statusModal: {
            show: false,
            status: internalStatus,
            approvedBy: approvedByName,
            document: updatedDocument,
          },
        });
        return true;
      }
    } finally {
      setLoading(false);
    }
  };

  const fields: Field[] = [
    {
      name: "status",
      label: "Estado:",
      type: "options",
      options: [
        { value: "approved", label: "Aprobado" },
        { value: "disapproved", label: "Desaprobado" },
        { value: "unapproved", label: "Sin aprobar" },
      ],
      required: true,
    },
  ];

  return (
    <AndesModal
      message="Actualizar estado del documento"
      handleClose={resetCommonComponent}
      handleSubmit={handleSubmit(({ status }) => handleToggle(status))}
    >
      <Box mt={2} width={"100%"}>
        <AutomaticForm control={control} fields={fields} errors={errors} />
      </Box>
    </AndesModal>
  );
};

export default StatusModal;
