import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { APIPostWithError } from "../../../Services/authenticated";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import SignatureRow from "../../../screens/Dashboard/ElectronicSignature/SignatureStatusTable/SignatureRow/SignatureRow";
import { colorPallette } from "../../../Common/theme";
import { SignatureData } from "../../../Types/Signer.types";
import { useAtomValue } from "jotai";
import { Box, Modal } from "@mui/material";
import {
  commonComponentAtom,
  resetCommonComponent,
} from "../../../jotai/commonComponentAtom.jotai";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SignatureStatusModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentDetails, setCurrentDetails] = useState<SignatureData | any>(
    null
  );
  const { currentSignature, showSignatureStatusModal } =
    useAtomValue(commonComponentAtom);

  const getDetails = async () => {
    if (currentSignature?.documentToken) {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();

        const data = await APIPostWithError(
          "/electronic-signature/get-signature-details",
          accessToken,
          { token: currentSignature.documentToken }
        );

        if (data?.details) {
          setCurrentDetails({
            ...data.details,
            userId: currentSignature.userId,
            createdBy: currentSignature.createdBy,
            creatorPhotoUrl: currentSignature.creatorPhotoUrl,
            dateCreated: currentSignature.dateCreated,
          });
        } else {
          resetCommonComponent();
          setLoading(false);
          setAppError("Error al obtener los detalles de firma");
        }
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSignature]);

  return (
    <Modal open={showSignatureStatusModal} onClose={resetCommonComponent}>
      <Box
        sx={{
          width: "90vw",
          maxWidth: "1400px",
          position: "fixed",
          top: "200px",
          left: "50%",
          transform: "translateX(-50%)",
          textAlign: "left",
          bgcolor: "white",
          p: "8px",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            p: "4px 12px",
            cursor: "pointer",
            position: "absolute",
            right: "10px",
            top: "10px",
            borderRadius: "4px",
            fontSize: "1rem",
          }}
          onClick={resetCommonComponent}
        >
          <FontAwesomeIcon icon={faTimes} color={colorPallette.primary} />
        </Box>
        <SignatureRow
          currentSignature={currentSignature}
          data={currentDetails}
          reload={false}
          isModal
        />
      </Box>
    </Modal>
  );
};

export default SignatureStatusModal;
