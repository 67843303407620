import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  TextRun,
} from "docx";

import {
  kw_city_footer,
  kw_city_logo,
  kw_header_left,
} from "../docImages/kw-city"; // Importa la nueva imagen

export const styles_kw_city = {
  companyId: ["12424"],
  companyName: "Keller Williams City",
  font: "Arial",
  titleSize: 22,
  textSize: 20,
  lineSpacing: 470,
  marginRight: 780,
  marginLeft: 780,
  beforeParagraph: 200,

  header: new Header({
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        spacing: { before: 0, after: 0 },
        children: [
          new ImageRun({
            data: kw_header_left,
            transformation: {
              width: 250,
              height: 70,
            },
          }),
          // Espacio adicional para mover la segunda imagen hacia la derecha
          new TextRun({
            text: "                                                                      ",
          }),
          new ImageRun({
            data: kw_city_logo,
            transformation: {
              width: 180,
              height: 75,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: { before: 500, after: 350 },
        alignment: AlignmentType.CENTER,
        indent: { left: -300 },
        children: [
          new ImageRun({
            data: kw_city_footer,
            transformation: {
              width: 500,
              height: 60,
            },
          }),
        ],
      }),
    ],
  }),
};
