import React, { useState } from "react";
import {
  faPencil,
  faLink,
  faTrash,
  IconDefinition,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../../../../../uiComponents/ActionButton/ActionButton";
import "./SignerActions.styles.css";
import { setAppError, setLoading } from "../../../../../Utils/modalMethods";
import { APIPostWithError } from "../../../../../Services/authenticated";
import {
  resetCommonComponent,
  setCommonComponents,
} from "../../../../../jotai/commonComponentAtom.jotai";
import { Box } from "@mui/material";
import { setConfirmationModalAtom } from "../../../../../jotai/confimationModalAtom.jotai";
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  data: any;
  signerCount: number;
};

type ActionButtonType = {
  icon: IconDefinition;
  label: string;
  onClick: () => void;
  condition: boolean;
};

const SignerActions: React.FC<Props> = ({ data, signerCount }) => {
  const [showToast, setShowToast] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const openSignerEmailModal = () => {
    resetCommonComponent();
    setCommonComponents({
      showSignerEmailModal: true,
      currentSigner: data,
    });
  };

  const deleteOneSigner = async (data: any) => {
    const accessToken = await getAccessTokenSilently();
    try {
      setLoading(true);
      const res = await APIPostWithError(
        "/electronic-signature/delete-signer",
        accessToken,
        {
          signerToken: data.token,
        }
      );
      if (res.signerDeleted === true) {
        setTimeout(() => {
          setCommonComponents({
            showSuccessModal: true,
            message: "Firmante Eliminado Exitosamente",
            subMessage: "Ya no aparecerá en el documento.",
            disclaimer: "",
          });
        }, 500);
        resetCommonComponent();
      } else {
        setTimeout(() => {
          setAppError();
        }, 50);
        resetCommonComponent();
      }
    } finally {
      setLoading(false);
    }
  };

  const copySignLink = async () => {
    const signatureUrl = data.sign_url;

    try {
      await navigator.clipboard.writeText(signatureUrl);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    } catch (error) {
      console.error("Error al copiar el enlace:", error);
      setAppError("Error al copiar el link");
    }
  };

  const openDeleteConfirmModal = () => {
    resetCommonComponent();
    setConfirmationModalAtom({
      show: true,
      message: "Confirme eliminación",
      subMessage: "¿Seguro desea eliminar a este firmante?",
      disclaimer: "*No se podrá deshacer",
      onConfirm: () => deleteOneSigner(data),
    });
  };

  const actions: ActionButtonType[] = [
    {
      icon: faPencil,
      label: "Editar email",
      onClick: openSignerEmailModal,
      condition: data.status !== "signed",
    },
    {
      icon: faLink,
      label: "Copiar link para firmar",
      onClick: copySignLink,
      condition: true,
    },
    {
      icon: faTrash,
      label: "Eliminar firmante",
      onClick: openDeleteConfirmModal,
      condition: signerCount > 1 && data.status !== "signed",
    },
  ];

  return (
    <div className="signer-actions-wrapper">
      {actions
        .filter((action) => action.condition)
        .map((action, index) => (
          <ActionButton
            key={index}
            icon={action.icon}
            label={action.label}
            onClick={action.onClick}
          />
        ))}
      {showToast && (
        <Box className="toast b-primary white bold" sx={{ maxWidth: "700px" }}>
          Link para firmar copiado! Envíelo al firmante y podrá firmar
          accediendo al link.
        </Box>
      )}
    </div>
  );
};

export default SignerActions;
