import { useEffect } from "react";
import Navbar from "./screens/Navbar/Navbar";

import { setAnswers } from "./redux/answers/answers.actions";

import { Route, useLocation } from "react-router-dom";

import Empresas from "./screens/Empresas/Empresas";

import DocumentCreator from "./screens/DocumentCreator/DocumentCreator";
import TestDocCreator from "./screens/DocumentCreator/TestDocCreator";
import Pago from "./screens/Pago/Pago";
import DocumentSelector from "./screens/DocumentSelector/DocumentSelector";
import SessionRedirect from "./screens/SectionRedirect/SectionRedirect";
import Profile from "./Authentication/Profile/Profile";
import LoginRedirect from "./Authentication/LoginRedirect/LoginRedirect";
import PaymentError from "./screens/PaymentError/PaymentError";

import * as ContratoDeAlquilerQuestions from "./data/ContratoDeAlquilerQuestions";
import * as ContratoDeServiciosQuestions from "./data/ContratoServicioQuestions";
import * as ContratoDeTrabajoAPlazoFijo from "./data/ContratoTrabajoPlazoFijoQuestions";
import * as ContratoDePrestamoDeDineroQuestions from "./data/ContratoPrestamodeDineroQuestions";
import * as ContratoDeAlquilerTemporalQuestions from "./data/ContratoAlqTemporalQuestions";
import * as NdaQuestions from "./data/NdaQuestions";
import * as TestQuestions1 from "./data/TestQuestions1";
import * as TestQuestions2 from "./data/TestQuestions2";
import * as TestQuestions3 from "./data/TestQuestions3";
import * as TestQuestions4 from "./data/TestQuestions4";
import * as TestQuestions5 from "./data/TestQuestions5";
import * as TestQuestions6 from "./data/TestQuestions6";
import * as TestQuestions7 from "./data/TestQuestions7";
import * as ComodatoQuestions from "./data/ComodatoQuestions";
import * as PagareQuestions from "./data/PagareQuestion";
import * as ReservadeLocacionQuestions from "./data/ReservadeLocacionQuestions";
import * as ReciboQuestions from "./data/ReciboQuestions";
import * as Questions from "./data/questionsExporter";

import ErrorScreen from "./screens/ErrorScreen/ErrorScreen";
import EmailVerification from "./Authentication/EmailVerification/EmailVerification";
import SupportConfig from "./Authentication/SupportConfig/SupportConfig";
import PrivacyPolicy from "./screens/Policies/PrivacyPolicy";
import TermsAndConditions from "./screens/Policies/TermsConditions";
import LegalDisclaimer from "./screens/Policies/LegalDisclamimer";
import Contact from "./screens/AboutUs/Contact/Contact";
import SubscribeScreen from "./screens/Suscription/SubscribeScreen/SubscribeScreen";

import DataDownloader from "./DataDownloader/DataDownloader";
import DataUploader from "./DataDownloader/DataUploader";

import CommonComponentRender from "./screens/CommonComponentRender/CommonComponentRender";
import CompanyDocumentCreator from "./screens/CompanyDocumentCreator/CompanyDocumentCreator";
import CompanyDocumentGenerator from "./screens/DocumentCreator/Download/CompanyDocumentGenerator/CompanyDocumentGenerator";
import DataUpdater from "./DataDownloader/DataUpdater";
import TestDocumentGenerator from "./screens/DocumentCreator/Download/TestDocumentGenerator/TestDocumentGenerator";
import DocxEditorMain from "./components/DocxEditor/DocxEditorMain";
import InternalGenerator from "./app/InternalGenerator/InternalGenerator";
import GeneratorForm from "./app/GeneratorForm/GeneratorForm";
import InternalDocumentGenerator from "./app/InternalDocumentGenerator/InternalDocumentGenerator";
import CompanyDocumentUploader from "./app/Backoffice/Internal/CompanyDocumentUploader";
import CompanyDocumentUpdater from "./app/Backoffice/Internal/CompanyDocumentUpdater";
import HomeTop from "./screens/Home/HomeTop";
import TrustedCompanies from "./screens/Home/TrustedCompanies/TrustedCompanies";
import ContractLifecycle from "./screens/Home/ContractLifecycle";
import BenefitCards from "./screens/Home/BenefitsCards";
import { ProductTour } from "./screens/ProductTour/ProductTour";
import { ClientsCarousel } from "./screens/Home/ClientsCarousel";
import { YoutubeVideoSection } from "./screens/Home/YoutubeVideoSection";
import Dashboard from "./screens/Dashboard/Dashboard";
import Footer from "./screens/Footer/Footer";
import DocumentStarter from "./app/InternalDocumentGenerator/DocumentStarter";
import PrevUserExistant from "./Authentication/PrevUserExistant/PrevUserExistant";
import { connect } from "react-redux";
import CompanyDocumentUploaderExternal from "./app/Backoffice/External/CompanyDocumentUploaderExternal";
import EditorCheckWrapper from "./Authentication/AuthenticationWrappers/EditorCheckWrapper";
import RoleCheckedComponent from "./Authentication/AuthenticationWrappers/RoleCheckedComponent";
import CONSTANTS from "./Common/constants";

function App({ setAnswers }: any) {
  const location = useLocation();

  useEffect(() => {
    if (sessionStorage.answers) {
      const sessionAnswers = JSON.parse(sessionStorage.answers);
      setAnswers(sessionAnswers);
    }
  }, [setAnswers]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="App">
      <Navbar />
      <div className="rest-of-app">
        <Route exact path={CONSTANTS.ROUTES.HOME}>
          <HomeTop />
          <TrustedCompanies />
          <ClientsCarousel />
          <ContractLifecycle />
          <YoutubeVideoSection />
          <BenefitCards />
          <Footer />
        </Route>
        <Route path={CONSTANTS.ROUTES.DOCX_EDITOR}>
          <DocxEditorMain />
        </Route>
        <Route path={CONSTANTS.ROUTES.DOCUMENT_SELECTOR}>
          <DocumentSelector />
        </Route>
        <Route path={CONSTANTS.ROUTES.SUSCRIPTION}>
          <Empresas />
        </Route>
        <Route path={CONSTANTS.ROUTES.DASHBOARD}>
          <Dashboard />
        </Route>
        <Route path={CONSTANTS.ROUTES.COMPANY_DOCUMENT_CREATOR}>
          <CompanyDocumentCreator />
        </Route>
        <Route path={CONSTANTS.ROUTES.SERVICE_CONTRACT}>
          <DocumentCreator
            data={ContratoDeServiciosQuestions.ContratoDeServicios}
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.RENT_CONTRACT}>
          <DocumentCreator
            data={ContratoDeAlquilerQuestions.ContratoDeAlquiler}
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.EMPLOYMENT_CONTRACT}>
          <DocumentCreator
            data={ContratoDeTrabajoAPlazoFijo.ContratoDeTrabajoAPlazoFijo}
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.MONEY_LOAN_AGREEMENT}>
          <DocumentCreator
            data={
              ContratoDePrestamoDeDineroQuestions.ContratoDePrestamoDeDinero
            }
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEMPORARY_RENT_CONTRACT}>
          <DocumentCreator
            data={
              ContratoDeAlquilerTemporalQuestions.ContratoDeAlquilerTemporal
            }
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.NDA}>
          <DocumentCreator data={NdaQuestions.NDA} />
        </Route>
        <Route path={CONSTANTS.ROUTES.COMODATO}>
          <DocumentCreator data={ComodatoQuestions.Comodato} />
        </Route>
        <Route path={CONSTANTS.ROUTES.PAGARE}>
          <DocumentCreator data={PagareQuestions.Pagare} />
        </Route>
        <Route path={CONSTANTS.ROUTES.LOCATION_RESERVATION}>
          <DocumentCreator
            data={ReservadeLocacionQuestions.ReservaDeLocacion}
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.RECEIPT}>
          <DocumentCreator data={ReciboQuestions.Recibo} />
        </Route>
        <Route path={CONSTANTS.ROUTES.OLD_LAW_RENT_CONTRACT}>
          <DocumentCreator data={Questions.contratoDeAlquilerNormal} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST1}>
          <TestDocCreator data={TestQuestions1.Test1} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST2}>
          <TestDocCreator data={TestQuestions2.Test2} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST3}>
          <TestDocCreator data={TestQuestions3.Test3} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST4}>
          <TestDocCreator data={TestQuestions4.Test4} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST5}>
          <TestDocCreator data={TestQuestions5.Test5} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST6}>
          <TestDocCreator data={TestQuestions6.Test6} />
        </Route>
        <Route path={CONSTANTS.ROUTES.TEST7}>
          <TestDocCreator data={TestQuestions7.Test7} />
        </Route>
        <Route path={CONSTANTS.ROUTES.BAD}>
          <div>Error, por favor reintentar</div>
        </Route>
        <Route path={CONSTANTS.ROUTES.PAYMENT}>
          <Pago />
        </Route>
        <Route path={CONSTANTS.ROUTES.DOWNLOAD_TEST}>
          <TestDocumentGenerator />
        </Route>
        <Route path={CONSTANTS.ROUTES.COMPANY_DOCUMENT_DOWNLOAD}>
          <CompanyDocumentGenerator />
        </Route>
        <Route path={CONSTANTS.ROUTES.REDIRECT} component={SessionRedirect} />
        <Route
          path={CONSTANTS.ROUTES.REDIRECT_ID}
          component={SessionRedirect}
        />
        <Route path={CONSTANTS.ROUTES.PROFILE} component={Profile} />
        <Route
          path={CONSTANTS.ROUTES.LOGIN_REDIRECT}
          component={LoginRedirect}
        />
        <Route
          path={CONSTANTS.ROUTES.EMAIL_VERIFICATION}
          component={EmailVerification}
        />
        <Route
          path={CONSTANTS.ROUTES.SUPPORT_CONFIG}
          component={SupportConfig}
        />
        <Route
          path={CONSTANTS.ROUTES.PREV_USER_EXISTANT}
          component={PrevUserExistant}
        />
        <Route path={CONSTANTS.ROUTES.ERROR} component={ErrorScreen} />
        <Route path={CONSTANTS.ROUTES.PAYMENT_ERROR} component={PaymentError} />
        <Route
          path={CONSTANTS.ROUTES.SUBSCRIBE_CONTRACT}
          component={SubscribeScreen}
        />
        <Route
          path={CONSTANTS.ROUTES.PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
        <Route
          path={CONSTANTS.ROUTES.TERMS_CONDITIONS}
          component={TermsAndConditions}
        />
        <Route
          path={CONSTANTS.ROUTES.LEGAL_DISCLAIMER}
          component={LegalDisclaimer}
        />
        <Route path={CONSTANTS.ROUTES.PRODUCT_TOUR} component={ProductTour} />
        <Route path={CONSTANTS.ROUTES.CONTACT} component={Contact} />
        <Route
          path={CONSTANTS.ROUTES.DATA_DOWNLOADER}
          component={DataDownloader}
        />
        <Route path={CONSTANTS.ROUTES.DATA_UPLOADER} component={DataUploader} />
        <Route path={CONSTANTS.ROUTES.DATA_UPDATER} component={DataUpdater} />
        <Route
          path={CONSTANTS.ROUTES.INTERNAL_GENERATOR}
          component={InternalGenerator}
        />
        <Route
          path={CONSTANTS.ROUTES.GENERATOR_FORM}
          component={GeneratorForm}
        />
        <Route path={CONSTANTS.ROUTES.COMPANY_DOCUMENT_UPLOADER}>
          <EditorCheckWrapper
            InternalUserComponent={CompanyDocumentUploader}
            ExternalUserComponent={CompanyDocumentUploaderExternal}
          />
        </Route>
        <Route path={CONSTANTS.ROUTES.COMPANY_DOCUMENT_UPDATER}>
          <RoleCheckedComponent
            includeInternalUser
            admittedRoles={[
              CONSTANTS.ROLES.USER_EDITOR,
              CONSTANTS.ROLES.ADMIN_EDITOR,
            ]}
            component={CompanyDocumentUpdater}
          />
        </Route>
        <Route
          path={CONSTANTS.ROUTES.INTERNAL_DOCUMENT_GENERATOR}
          component={InternalDocumentGenerator}
        />
        <Route
          path={CONSTANTS.ROUTES.DOCUMENT_STARTER}
          component={DocumentStarter}
        />
      </div>
      <CommonComponentRender />
    </div>
  );
}

const mapDispatchToProps = {
  setAnswers,
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
