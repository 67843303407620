import { styles_baigun } from "./baigun";
import { styles_cadema } from "./cadema";
import { styles_coldwell } from "./coldwell";
import { styles_guimat } from "./guimat";
import { styles_soldati } from "./soldati";
import { styles_soldati_conecta } from "./soldatiConecta";
import { styles_toribio } from "./toribio";
import { styles_fabian_achaval } from "./fabian-achaval";
import { styles_izrastzoff } from "./izrastzoff";
import { styles_clickAparts } from "./clickAparts";
import { styles_okeefe } from "./okeefe";
import { styles_flechabus } from "./flechabus";
import { styles_propicia } from "./propicia";
import { styles_rp_soluciones } from "./rp-soluciones";
import { styles_loza_ordano } from "./loza-ordano";
import { styles_kw_pilar } from "./kw-pilar";
import { styles_kw_city } from "./kw-city";
import { styles_kw_on } from "./kw-on";
import { styles_mariana_rebora } from "./mariana-rebora";
import { styles_bresson } from "./bresson";
import { styles_szlit } from "./szlit";

export const stylesPerCompany = [
  styles_soldati,
  styles_soldati_conecta,
  styles_guimat,
  styles_baigun,
  styles_coldwell,
  styles_cadema,
  styles_toribio,
  styles_fabian_achaval,
  styles_izrastzoff,
  styles_clickAparts,
  styles_okeefe,
  styles_flechabus,
  styles_propicia,
  styles_rp_soluciones,
  styles_loza_ordano,
  styles_kw_pilar,
  styles_kw_city,
  styles_kw_on,
  styles_mariana_rebora,
  styles_bresson,
  styles_szlit,
];
