import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faHourglassHalf,
  faCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import TableDate from "../../../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import Image from "../../../../../uiComponents/Image/Image";
import SignerActions from "./SignerActions";
import { setAppError, setLoading } from "../../../../../Utils/modalMethods";
import { ProfilePicAndName } from "../../../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import { findUserNameById } from "../../../../../Utils/commonTableActions";
import { Box, Button, Typography } from "@mui/material";
import { colorPallette } from "../../../../../Common/theme";
import { NewSigner, SignatureData } from "../../../../../Types/Signer.types";
import { downloadExternalPublicS3file } from "../../../../../Utils/S3Download";

const getStatus = (status: string) => {
  if (status === "signed") {
    return "Firma Completa";
  }
  return "Pendiente de Firma";
};

const getSignerStatus = (status: string) => {
  if (status === "signed") {
    return "Ya firmó";
  }
  return "Aun no firmó";
};

const getIcon = (status: string) => {
  if (status === "signed") {
    return faCheckCircle;
  }
  return faHourglassHalf;
};

type SignatureSummaryProps = {
  data: SignatureData;
};

const SignatureSummary: React.FC<SignatureSummaryProps> = ({ data }) => {
  const userName = findUserNameById(data.userId);

  return (
    <Box
      sx={{
        bgcolor: colorPallette.white,
        borderRadius: "4px 0 0 4px",
        p: "12px 16px",
        minHeight: "180px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "28%",
        minWidth: "400px",
        borderRight: "solid 1px rgb(243, 243, 251)",
      }}
    >
      <Box
        sx={{
          fontSize: "0.9rem",
          fontWeight: "bold",
          mb: "-12px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "20px",
        }}
      >
        <Image
          name="signature.png"
          style={{
            width: "24px",
            marginRight: "12px",
          }}
        />
        {data.name}
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            justifyContent: "flex-start",
            mt: "25px",
            fontSize: "0.9rem",
          }}
        >
          {getStatus(data.status)}
          <FontAwesomeIcon
            color={
              data.status === "signed"
                ? colorPallette.primary
                : colorPallette.secondary
            }
            icon={getIcon(data.status)}
            style={{ marginLeft: "8px" }}
            className={`${
              data.status === "signed"
                ? colorPallette.primary
                : colorPallette.tertiary
            }`}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            justifyContent: "flex-start",
            mt: "25px",
            fontSize: "0.94rem",
            position: "relative",
            top: "-10px",
          }}
        >
          <TableDate date={data.dateCreated} />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "300px",
            justifyContent: "flex-start",
            mt: "25px",
          }}
        >
          <ProfilePicAndName name={userName} image={data.creatorPhotoUrl} />
        </Box>
      </Box>
    </Box>
  );
};

type SignersProps = {
  signers: NewSigner[];
  currentSignature: any;
};

const Signers: React.FC<SignersProps> = ({ signers, currentSignature }) => {
  return (
    <Box
      sx={{
        p: "12px 0px",
        ml: 0,
        bgcolor: colorPallette.white,
        borderRadius: 0,
        width: "53%",
        minWidth: "400px",
        borderRight: "solid 1px rgb(243, 243, 251)",
      }}
    >
      <Box fontWeight={"bold"} fontSize={"0.9rem"} pl={3}>
        Firmantes:
      </Box>

      <Box
        sx={{
          maxHeight: 300,
          overflowY: "auto",
          padding: "8px 8px 8px 24px",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colorPallette.white,
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colorPallette.secondary,
            borderRadius: "8px",
            cursor: "pointer",
          },
        }}
      >
        {signers?.length > 0 &&
          signers.map((sig: NewSigner) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: "8px",
              }}
              key={sig.name}
            >
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      color: colorPallette.secondary,
                      border: "2px solid rgb(215, 219, 255)",
                      borderRadius: "4px",
                      height: "18px",
                      width: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      transform: "scale(0.9)",
                    }}
                  >
                    {sig.status === "signed" && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="sig-check primary"
                      />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      margin: "0 8px",
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      opacity: sig.status !== "signed" ? 0.6 : 1,
                      color: colorPallette.dark,
                    }}
                  >
                    {sig.name}
                  </Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography
                    color={colorPallette.primary}
                    sx={{
                      opacity: sig.status !== "signed" ? 0.6 : 1,
                      fontSize: "0.8rem",
                    }}
                  >
                    {sig.email}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "0.7rem",
                      fontStyle: "italic",
                      pl: "5px",
                      color: colorPallette.dark,
                      opacity: sig.status !== "signed" ? 0.6 : 1,
                    }}
                  >
                    ({getSignerStatus(sig.status)})
                  </Typography>
                </Box>
                <SignerActions data={sig} signerCount={signers.length} />
              </Box>
            </Box>
          ))}
      </Box>
    </Box>
  );
};

type SignatureActionsProps = {
  signaturesData: SignatureData;
  isModal: boolean;
};

const SignatureActions: React.FC<SignatureActionsProps> = ({
  signaturesData,
}) => {
  const isAnySignerSigned = () => {
    return signaturesData.signers?.some((signer) => signer.status === "signed");
  };

  const handleDownload = async () => {
    try {
      if (isAnySignerSigned() && signaturesData.signed_file) {
        setLoading(true);
        await downloadExternalPublicS3file(
          signaturesData.signed_file,
          "pdf",
          signaturesData.name
        );
      } else {
        setAppError(
          "Firmar el documento",
          "Se requiere que al menos un firmante complete su firma para descargar"
        );
      }
    } catch (error) {
      console.error("Error al descargar el archivo firmado:", error);
      setAppError("Error de sistema, inténtelo nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        p: "12px 16px",
        gap: "20px",
        ml: "0px",
        bgcolor: colorPallette.white,
        borderRadius: 0,
        width: "17%",
        minWidth: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box fontWeight={"bold"} fontSize={"0.9rem"}>
        Acciones:
      </Box>
      <Button
        startIcon={
          <FontAwesomeIcon style={{ fontSize: "0.8rem" }} icon={faDownload} />
        }
        onClick={handleDownload}
        variant="contained"
        sx={{
          width: "100%",
        }}
      >
        Descargar Firmado
      </Button>
    </Box>
  );
};

const SignatureRow: React.FC<any> = ({
  currentSignature,
  data,
  isModal = false,
}) => {
  if (!data || !data.signers) {
    return <></>;
  }

  return (
    <Box
      sx={{
        m: "10px 0",
        display: "flex",
        width: "100%",
        maxWidth: "100%",
      }}
    >
      <SignatureSummary data={data} />
      <Signers currentSignature={currentSignature} signers={data.signers} />
      <SignatureActions signaturesData={data} isModal={isModal} />
    </Box>
  );
};

export default SignatureRow;
