import { Box } from "@mui/material";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { useForm } from "react-hook-form";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import {
  commonComponentAtom,
  resetCommonComponent,
} from "../../../jotai/commonComponentAtom.jotai";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../../Services/authenticated";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignature,
  faCameraRetro,
  faIdCard,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { AndesTooltip } from "../../../uiComponents/AndesTooltip/AndesTooltip";
import { useAtom, useAtomValue } from "jotai";
import { useHistory } from "react-router-dom";
import { signersAtom } from "../../../jotai/atoms.jotai";
import "./styles.css";

const AddNewSignerModal = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const { currentSignature } = useAtomValue(commonComponentAtom);
  const [, setSigners] = useAtom(signersAtom);

  const fields: Field[] = [
    {
      name: "name",
      label: "Nombre del Firmante",
      type: "text",
      required: true,
    },
    {
      name: "email",
      label: "Email del Firmante",
      type: "text",
      required: true,
    },
    {
      name: "confirmEmail",
      label: "Confirmar Email del Firmante",
      type: "text",
      required: true,
    },
  ];

  const addSigner = async (data: any) => {
    const { name, email, confirmEmail, photo, idCard } = data;

    if (email !== confirmEmail) {
      setAppError(
        "Emails no coinciden",
        "Por favor revise los emails ingresados."
      );
      return;
    }

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/electronic-signature/add-new-signer",
        accessToken,
        {
          doc_token: currentSignature?.documentToken,
          name,
          email,
          photo: !!photo,
          id_card_required: !!idCard,
          screen_signature_required: true,
        }
      );

      if (res.success) {
        resetCommonComponent();

        setSigners((prevSigners) => [
          ...prevSigners,
          ...(Array.isArray(res.signer) ? res.signer : [res.signer]),
        ]);

        history.push("/dashboard/ESignersDataLinks");
      } else {
        console.error("Error adding new signer:", res.message);
      }
    } catch (error) {
      console.error("Error adding new signer:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AndesModal
      message="Agregar nuevo Firmante"
      subMessage=""
      submitButtonText="Agregar"
      handleClose={resetCommonComponent}
      handleSubmit={handleSubmit(addSigner)}
    >
      <Box sx={{ width: "100%", mt: 5 }}>
        <AutomaticForm control={control} fields={fields} errors={errors} />
        <Box
          sx={{
            "& input": {
              transform: "scale(1.1)",
            },
            "& label": {
              fontSize: "0.9rem",
            },
          }}
          className="modal-e-sign-form-checkbox-wrapper"
        >
          <div className="modal-e-sign-form-checkbox">
            <AndesTooltip
              title="Firma en pantalla"
              description="Se requerirá al firmante que firme con su dedo o mouse (Móvil/PC)."
              icon={faInfoCircle}
              placement="top"
            />
            <input
              type="checkbox"
              {...control.register("screenSignature")}
              defaultChecked
              disabled
              style={{ opacity: 1, marginLeft: 18 }}
            />
            <label style={{ opacity: 0.4 }}>
              <FontAwesomeIcon
                icon={faSignature}
                className="margin-icon primary"
              />
              Firma en Pantalla
            </label>
          </div>
          <div className="modal-e-sign-form-checkbox">
            <AndesTooltip
              title="Identificación con Auto Foto"
              description="Se requerirá al firmante que se tome una autofoto (Selfie) del rostro para identificar que es la persona que dice ser."
              icon={faInfoCircle}
              placement="top"
            />
            <input
              type="checkbox"
              {...control.register("photo")}
              defaultChecked
              style={{ marginLeft: 18 }}
            />
            <label>
              <FontAwesomeIcon
                icon={faCameraRetro}
                className="margin-icon primary"
              />
              Auto Foto
            </label>
          </div>
          <div className="modal-e-sign-form-checkbox">
            <AndesTooltip
              title="Documento de Identidad"
              description="Se requerirá al firmante que saque una foto de su DNI."
              icon={faInfoCircle}
              placement="top"
            />
            <input
              type="checkbox"
              {...control.register("idCard")}
              defaultChecked
              style={{ marginLeft: 18 }}
            />
            <label>
              <FontAwesomeIcon
                icon={faIdCard}
                className="margin-icon primary"
              />
              Documento de Identidad
            </label>
          </div>
        </Box>
      </Box>
    </AndesModal>
  );
};

export default AddNewSignerModal;
