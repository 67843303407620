import React, { useEffect, useState } from "react";
import { APIGet, APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { Folder } from "../../../screens/Dashboard/DocumentsManager/Folder/Folder";
import { useAtomValue } from "jotai";
import { Stack } from "@mui/material";

import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../jotai/commonComponentAtom.jotai";
import { Document, updateDocumentOnAtom } from "../../../jotai/documents.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { colorPallette } from "../../../Common/theme";

interface FolderData {
  folderId: string;
  folderName: string;
}

const FolderModal: React.FC = () => {
  const [folders, setFolders] = useState<FolderData[] | null>(null);
  const { currentDocument } = useAtomValue(commonComponentAtom);
  const { getAccessTokenSilently } = useAuth0();

  const getFolders = async () => {
    try {
      const accesToken = await getAccessTokenSilently();
      const allFolders = await APIGet(
        "/folders/get-company-folders",
        accesToken
      );

      if (allFolders.folders && allFolders.folders.length > 0) {
        const sortedArray = allFolders.folders.sort((a: any, b: any) => {
          return b.folderId - a.folderId;
        });
        setFolders(sortedArray);
      } else {
        setAppError("Error al buscar las carpetas");
      }
    } catch {
      setAppError("Error al buscar las carpetas");
    }
  };

  const onChangeFolder = async (newFolderId: any) => {
    setLoading(true);
    const payload = {
      documentId: currentDocument.documentId,
      folderId: newFolderId,
    };

    try {
      const accessToken = await getAccessTokenSilently();

      const res = await APIPostWithError(
        "/doc-ref/move-document",
        accessToken,
        payload
      );

      if (res) {
        updateDocumentOnAtom({
          ...currentDocument,
          folder: newFolderId,
        });
        resetCommonComponent();
      } else {
        setAppError("Error al buscar las carpetas");
      }
    } catch {
      setAppError("Error al mover el documento");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFolders();
  }, []);

  const handleOpenNewFolderModal = () => {
    setCommonComponents({
      showNewFolderModal: true,
      currentDocument: currentDocument,
    });
  };

  return (
    <AndesModal
      message="Mover documento"
      subMessage="Mover a:"
      submitButtonText="Nueva carpeta"
      handleClose={resetCommonComponent}
      handleSubmit={handleOpenNewFolderModal}
    >
      <Stack
        sx={{
          height: "320px",
          overflow: "auto",
          mt: "20px",
          gap: 1,
          width: "100%",
          pr: 1.5,
          mb: 2,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colorPallette.white,
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colorPallette.secondary,
            borderRadius: "8px",
            cursor: "pointer",
          },
        }}
      >
        {folders &&
          folders.map((folder) => {
            return (
              <Folder
                key={folder.folderId}
                name={folder.folderName}
                onClick={() => {
                  onChangeFolder(folder.folderId);
                }}
              />
            );
          })}
      </Stack>
    </AndesModal>
  );
};

export default FolderModal;
