import React, { useEffect } from "react";
import { Box } from "@mui/material";
import pattern from "../../Assets/svg/pattern.svg";
import Image from "../../uiComponents/Image/Image";
import { cardStyle, colorPallette, gradients } from "../../Common/theme";
import { margin } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox, faTools } from "@fortawesome/free-solid-svg-icons";

const Maintenance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Box
      sx={{
        // background: gradients.dark,
        pt: 6,
        pb: 10,
        minHeight: "100vh",
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: 0.4,
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          //   filter: "invert(1)",
        }}
        src={pattern}
        alt="chat icon"
        title="Icono del chat"
      />
      <Image
        name="andesdocs.png"
        style={{ width: 320, margin: "40px 0" }}
        alt=""
      />

      <h1 style={{ color: colorPallette.dark, marginBottom: "40px" }}>
        <FontAwesomeIcon
          icon={faTools}
          style={{ color: colorPallette.primary, marginRight: "12px" }}
        />{" "}
        Estamos en Mantenimiento
      </h1>
      <Box className="intro" px={2} my={2}>
        Disculpá las molestias, estamos trabajando para mejorar tu experiencia.
        Volveremos en unos minutos. Cualquier consulta, no dudes en
        contactarnos.
      </Box>
      <Box
        className="center"
        sx={{
          maxWidth: 400,
          width: "90vw",
          borderRadius: "8px",
        }}
      >
        <div className="contact-grid center"></div>
        <div className="contact-grid-2 center">
          <div>
            <p className="contact-item contact-item-1">
              <i className="fas fa-phone-alt primary margin-icon"></i>
              Telefono:
            </p>
          </div>
          <div>
            <p className="contact-item contact-item-2">+54 11 3107 3107</p>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Maintenance;
