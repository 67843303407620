import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faHourglass2,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Box, Typography } from "@mui/material";
import { colorPallette } from "../../../Common/theme";

type Props = {
  status: string;
  approvedBy: string;
};

const TableEntryAproval: React.FC<Props> = ({ status, approvedBy }) => {
  const commonStyles = {
    position: "relative",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  };

  const approvalByStyles = {
    color: "rgba(0, 0, 0, 0.3)",
    position: "absolute",
    left: "2px",
    fontSize: "0.7rem",
    bottom: "-15px",
    fontWeight: 400,
  };

  if (status === "approved")
    return (
      <Box sx={{ ...commonStyles, color: colorPallette.primary }}>
        <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: "8px" }} />
        Aprobado
        {approvedBy && (
          <Typography sx={approvalByStyles} component="div">
            Por: {approvedBy}
          </Typography>
        )}
      </Box>
    );

  if (status === "disapproved")
    return (
      <Box sx={{ ...commonStyles, color: colorPallette.lightRed }}>
        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: "8px" }} />
        Desaprobado
        {approvedBy && (
          <Typography sx={approvalByStyles} component="div">
            Por: {approvedBy}
          </Typography>
        )}
      </Box>
    );

  return (
    <Box sx={{ ...commonStyles, color: colorPallette.primary }}>
      <Typography variant="body2" component="span" sx={{ opacity: 0.7 }}>
        <FontAwesomeIcon icon={faHourglass2} style={{ marginRight: "8px" }} />
        Sin Aprobar
      </Typography>
    </Box>
  );
};

export default TableEntryAproval;
