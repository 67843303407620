import {
  resetCommonComponent,
  setCommonComponents,
  setLoadingAtom,
} from "../jotai/commonComponentAtom.jotai";

export const closeErrorModal = () => {
  resetCommonComponent();
};

export const setAppError = (
  message?: string,
  subMessage?: string,
  disclaimer?: string
) => {
  setCommonComponents({
    showErrorModal: true,
    message: message || "Error",
    subMessage: subMessage || "Hubo un error de sistema. Intentelo más tarde.",
    disclaimer:
      disclaimer || "Si tiene un problema persistente, contacte con soporte.",
  });
};

export const setLoading = (bool: boolean = false) => {
  setLoadingAtom(bool);
};
