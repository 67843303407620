import { Drawer, List, ListItem, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandshakeAngle,
  faHeadset,
  faFile,
  faChalkboard,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import "./MenuDrawer.css";
import { colorPallette } from "../../Common/theme";
import CONSTANTS from "../../Common/constants";

type Props = {
  isOpen: boolean;
  toggleDrawer: (isOpen: boolean) => void;
  isAuthenticated: boolean;
};

export default function MenuDrawer({
  isOpen,
  toggleDrawer,
  isAuthenticated,
}: Props) {
  const history = useHistory();

  const handleNavigation = (path: string) => {
    history.push(path);
    toggleDrawer(false);
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={() => toggleDrawer(false)}>
      <List
        sx={{
          width: "300px",
          height: "100%",
          bgcolor: colorPallette.darkest,
        }}
      >
        <ListItem sx={{ justifyContent: "center", pb: 5 }}>
          <img
            src={process.env.PUBLIC_URL + "/images/andesdocs.png"}
            alt="logo-word"
            title="Logo word"
            style={{ width: "200px", height: "auto" }}
            onClick={() => handleNavigation(CONSTANTS.ROUTES.HOME)}
          />
        </ListItem>
        {!isAuthenticated ? (
          <>
            <ListItem
              sx={{ gap: 1, my: 1 }}
              button
              onClick={() => handleNavigation(CONSTANTS.ROUTES.CONTACT)}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color={colorPallette.primary}
                icon={faHeadset}
              />
              <Typography variant="h6" color={colorPallette.white}>
                Contacto
              </Typography>
            </ListItem>
            <ListItem
              sx={{ gap: 1, my: 1 }}
              button
              onClick={() => handleNavigation(CONSTANTS.ROUTES.SUSCRIPTION)}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color={colorPallette.primary}
                icon={faHandshakeAngle}
              />
              <Typography variant="h6" color={colorPallette.white}>
                Planes
              </Typography>
            </ListItem>
            <ListItem
              sx={{ gap: 1, my: 1 }}
              button
              onClick={() => handleNavigation(CONSTANTS.ROUTES.PRODUCT_TOUR)}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color={colorPallette.primary}
                icon={faVideo}
              />
              <Typography variant="h6" color={colorPallette.white}>
                Product Tour
              </Typography>
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              sx={{ gap: 1, my: 1 }}
              button
              onClick={() => handleNavigation("/dashboard/finished")}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color={colorPallette.primary}
                icon={faChalkboard}
              />
              <Typography variant="h6" color={colorPallette.white}>
                Dashboard
              </Typography>
            </ListItem>
            <ListItem
              sx={{ gap: 1, my: 1 }}
              button
              onClick={() => handleNavigation("/dashboard/documents")}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color={colorPallette.primary}
                icon={faFile}
              />
              <Typography variant="h6" color={colorPallette.white}>
                Documentos
              </Typography>
            </ListItem>
            <ListItem
              sx={{ gap: 1, my: 1 }}
              button
              onClick={() => handleNavigation(CONSTANTS.ROUTES.PRODUCT_TOUR)}
            >
              <FontAwesomeIcon
                fontSize="20px"
                color={colorPallette.primary}
                icon={faVideo}
              />
              <Typography variant="h6" color={colorPallette.white}>
                Product Tour
              </Typography>
            </ListItem>
          </>
        )}
      </List>
    </Drawer>
  );
}
