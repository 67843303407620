import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import TableDate from "../../../uiComponents/AndesTable/TableEntries/TableDate.component";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import {
  downloadDocumentFromS3,
  findUserNameById,
  findUserPhotoUrl,
} from "../../../Utils/commonTableActions";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import {
  faTrashAlt,
  faDownload,
  faPlus,
  faStopwatch20,
  faFileWord,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setEditorDocument } from "../../../redux/slices/EditorDataSlice";
import { useHistory } from "react-router-dom";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";
import {
  Document,
  removeDocumentFromAtom,
} from "../../../jotai/documents.jotai";
import { APIPostWithError } from "../../../Services/authenticated";
import { setConfirmationModalAtom } from "../../../jotai/confimationModalAtom.jotai";
import { useAuth0 } from "@auth0/auth0-react";
import { colorPallette } from "../../../Common/theme";
import { Box } from "@mui/material";
import TableEntryAproval from "../TableEntries/TableEntryAproval";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AndesTooltip } from "../../../uiComponents/AndesTooltip/AndesTooltip";
import { faEye } from "@fortawesome/free-regular-svg-icons";

type Props = {
  documents: any[];
  hideSearch?: boolean;
};

const DocumentsManagerTable: React.FC<Props> = ({ documents, hideSearch }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();

  const deleteDocument = async (document: any) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const { documentDeleted } = await APIPostWithError(
        "/doc-ref/delete-document",
        accessToken,
        document
      );
      if (documentDeleted) {
        removeDocumentFromAtom(document);
        setCommonComponents({
          showSuccessModal: true,
          message: "El documento fue borrado exitosamente",
        });
      }
    } catch {
      setAppError("Error al eliminar el documento");
    } finally {
      setLoading(false);
    }
  };

  const getImageName = (format: string) => {
    if (format === "pdf") {
      return "pdf2-icon.png";
    }
    if (format === "xls" || format === "xlsx") {
      return "excel-icon.png";
    }
    if (format === "ppt" || format === "pptx") {
      return "ppt-icon.png";
    }
    if (
      format === "png" ||
      format === "jpg" ||
      format === "jpeg" ||
      format === "heic"
    ) {
      return "pic-icon.png";
    }

    return "word-icon.png";
  };

  const alarmTableActions: TableAction[] = [
    {
      name: "Editar documento",
      icon: faFileWord,
      onClickWithParams: (data: any) => {
        const validFormats = ["word", "doc"];

        if (data.format && validFormats.includes(data.format.toLowerCase())) {
          dispatch(
            setEditorDocument({
              ...data,
              fileName: data.fileName,
              filePath: data.filePath,
            })
          );
          history.push("/dashboard/docx-editor");
        } else {
          setAppError(
            "Solo los archivos Word (docx) pueden editarse",
            "Si quiere editar este archivo descárguelo"
          );
        }
      },
    },
    {
      name: "Descargar",
      icon: faDownload,
      onClickAsync: (data: any, accessToken: any) =>
        downloadDocumentFromS3(data, accessToken),
    },
    {
      name: "Datos del documento",
      icon: faPlus,
      onClickWithParams: (data: Document) => {
        setCommonComponents({
          showDocumentDetailModal: true,
          currentDocument: data,
        });
      },
    },
    {
      name: "Definir Vencimiento",
      icon: faStopwatch20,
      onClickWithParams: (data: any) =>
        setCommonComponents({
          showExpirationModal: true,
          currentDocument: data,
        }),
    },
    {
      name: "Mover a carpeta",
      icon: faFolderOpen,
      onClickWithParams: (data: Document) => {
        setCommonComponents({
          showFolderModal: true,
          currentDocument: data,
        });
      },
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (currentDocument: Document) =>
        setConfirmationModalAtom({
          onConfirm: () => deleteDocument(currentDocument),
          message: "Confirme eliminación",
          subMessage: `¿Seguro desea eliminar el documento: ${currentDocument.fileName}?`,
          disclaimer:
            "*No se podrá deshacer, asegursese que no necesitará el documento en el futuro.",
          show: true,
        }),
      color: colorPallette.primary,
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(() => {
    return [
      {
        accessorKey: "fileName",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <TableEntryName
              name={cell.getValue()}
              image={getImageName(cell.row.original.format || "")}
              imageLarge
              subtitle={cell.row.original.format}
            />
          );
        },
        maxSize: 290,
        minSize: 290,
      },
      {
        accessorKey: "status",
        header: "Estado",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 210,
        maxSize: 210,
        Cell: ({ cell }: any) => {
          const { status, approvedBy } = cell.row.original;

          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 5,
              }}
            >
              <TableEntryAproval status={status} approvedBy={approvedBy} />

              <Box
                sx={{
                  color: colorPallette.dark,
                  cursor: "pointer",
                  fontSize: "0.9rem",
                  position: "relative",
                  zIndex: 10,
                  padding: "2px",
                  mr: 2,
                  transition:
                    "transform 0.2s, background-color 0.3s, color 0.3s, opacity 0.3s, border-radius 0.3s",
                  "&:hover": {
                    transform: "scale(1.3) rotate(8deg)",
                    borderRadius: "8px",
                  },
                }}
                onClick={() =>
                  setCommonComponents({
                    showStatusModal: true,
                    currentDocument: cell.row.original,
                  })
                }
              >
                <AndesTooltip description="Actualizar estado" placement="top">
                  <FontAwesomeIcon icon={faEye} className="refresh-icon" />
                </AndesTooltip>
              </Box>
            </Box>
          );
        },
      },
      {
        accessorKey: "documentKind",
        header: "Tipo de documento",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 180,
        maxSize: 180,
        Cell: ({ cell }: any) => {
          const value = cell.getValue() || "Sin definir";
          return (
            <Box
              sx={{
                opacity: value === "Sin definir" ? 0.6 : 1,
                color:
                  value === "Sin definir" ? colorPallette.primary : "inherit",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                maxWidth: "100%",
              }}
              title={value}
            >
              {value}
            </Box>
          );
        },
      },
      {
        accessorKey: "dateCreated",
        header: "Creación",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 140,
        maxSize: 140,
        Cell: ({ cell }: any) => {
          return <TableDate date={cell.getValue()} fallback="Sin definir" />;
        },
      },
      {
        accessorKey: "expirationDate",
        header: "Vence",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 145,
        maxSize: 145,
        Cell: ({ cell }: any) => {
          return (
            <TableDate
              date={cell.getValue()}
              fallback="Sin definir"
              color="b-secondary"
              fontColor={colorPallette.dark}
              hideTime
            />
          );
        },
      },
      {
        accessorKey: "userId",
        header: "Creado por",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 180,
        Cell: ({ cell }: any) => {
          const userPhotoUrl = findUserPhotoUrl(cell.getValue());
          const userName = findUserNameById(cell.getValue());
          return <ProfilePicAndName image={userPhotoUrl} name={userName} />;
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 190,
        Cell: ({ cell }) => (
          <TableActions
            actions={alarmTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ];
  }, []);

  return (
    <AndesTable
      documents={documents}
      columns={columns}
      initialSort={[{ id: "dateCreated", desc: true }]}
      hideSearch={hideSearch}
    />
  );
};

export default DocumentsManagerTable;
