import { useEffect, useState } from "react";
import DocumentCard from "../DocumentCard/DocumentCard";
import { APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem, Button, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTrash,
  faEllipsisV,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { getAndSetSheetDataByCompanyId } from "../../../Utils/getSheetData";
import { colorPallette } from "../../../Common/theme";
import { setConfirmationModalAtom } from "../../../jotai/confimationModalAtom.jotai";
import { useDispatch } from "react-redux";
import { setCompanyDocuments } from "../../../redux/slices/masterData";
import { deleteAndRedirect } from "../../../Utils/DocumentGeneratorMethods";
import CONSTANTS from "../../../Common/constants";
import "./styles.css";
import { setCommonComponents } from "../../../jotai/commonComponentAtom.jotai";

type Props = {
  companyDocuments: any[];
};

const DashboardEditSelector: React.FC<Props> = ({ companyDocuments }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [companyLogo, setCompanyLogo] = useState<any>(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const [displayCompanyDocuments, setDisplayCompanyDocuments] = useState<
    any[] | null
  >(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, doc: any) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocument(doc);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedDocument(null);
  };

  const handleDuplicateDocument = async () => {
    if (!selectedDocument) return;

    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();

      const response = await APIPostWithError(
        "/company-docs/duplicate-company-document",
        accessToken,
        {
          originalDocumentId: selectedDocument.documentId,
        }
      );

      if (response.success) {
        const duplicatedDocument = response.duplicatedDocument;
        duplicatedDocument.isNewlyDuplicated = true;

        const newDocuments = [...companyDocuments, duplicatedDocument].sort(
          (a, b) => a.documentName1.localeCompare(b.documentName1)
        );

        setDisplayCompanyDocuments(newDocuments);
        dispatch(setCompanyDocuments(newDocuments));
      }
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
      handleMenuClose();
    }
  };

  const handleDeleteDocument = () => {
    if (!selectedDocument) return;

    setConfirmationModalAtom({
      message: "¿Está seguro que desea eliminar este documento?",
      subMessage: `${selectedDocument.documentName}`,
      show: true,
      onConfirm: () => onDelete(selectedDocument.documentId),
      disclaimer: "*No se podrá recuperar una vez eliminado",
    });

    handleMenuClose();
  };

  useEffect(() => {
    window.sessionStorage.removeItem("custom_document_name");
    setLoading(true);
    if (localStorage.getItem("currentCompanyLogo")) {
      setCompanyLogo(localStorage.getItem("currentCompanyLogo"));
    }

    if (companyDocuments) {
      const filteredDocuments = companyDocuments.filter(
        (doc: any) => doc.generatorVersion === "2.0"
      );
      const sortedDocuments = [...filteredDocuments].sort((a, b) =>
        a.documentName1.localeCompare(b.documentName1)
      );
      setDisplayCompanyDocuments(sortedDocuments);
    }

    setLoading(false);
  }, [companyDocuments, user]);

  const getDocumentData = async (
    dataPath: string,
    generatorVersion: string,
    sheetId: string
  ) => {
    setLoading(true);
    try {
      const accesToken = await getAccessTokenSilently();
      const doc = await APIPostWithError(
        "/company-docs/get-company-document",
        accesToken,
        { dataPath, generatorVersion }
      );
      if (generatorVersion && generatorVersion === "2.0") {
        if (doc) {
          localStorage.setItem(
            "generator-form-document",
            JSON.stringify(doc.document)
          );
          localStorage.setItem(
            "generator-form-data",
            JSON.stringify(doc.questions)
          );
          localStorage.setItem(
            "generator-form-sections",
            JSON.stringify(doc.sections)
          );
          setLoading(false);
          history.push(CONSTANTS.ROUTES.INTERNAL_GENERATOR);
        }
        if (sheetId) {
          const companyData = await getAndSetSheetDataByCompanyId(
            sheetId,
            accesToken
          );
          if (companyData)
            localStorage.setItem(
              "companyData",
              JSON.stringify({ ...companyData, type: "sheetsDataBase" })
            );
        }
      }
    } catch {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const dispatch = useDispatch();

  const onDelete = async (id: string) => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();

      const document = companyDocuments.find((doc: any) => {
        return doc.documentId === id;
      });
      if (!document) {
        setAppError("Error al buscar el generador solicitado");
        return;
      }
      const documentToDelete = await APIPostWithError(
        "/company-docs/delete-company-document",
        accessToken,
        {
          documentId: document.documentId,
          companyId: document.companyId,
        }
      );
      if (documentToDelete.isDeleted) {
        setLoading(false);
        const newCompanyDocuments = companyDocuments.filter((doc: any) => {
          return doc.documentId !== id;
        });
        dispatch(setCompanyDocuments(newCompanyDocuments));
        setCommonComponents({
          showSuccessModal: true,
          message: "Se eliminó el generador exitosamente",
        });
      }
    } catch (e) {
      setAppError("Error al intentar borrar el generador seleccionado");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {displayCompanyDocuments && (
        <div className="dashboard-select0r-wrapper animate">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            alignContent="center"
          >
            <p className="dashboard-message">
              <i className="fas fa-play secondary margin-r" />
              En esta sección podés editar las preguntas y documentos que se
              generan
            </p>
            <Box display={"flex"} gap={2}>
              <Button
                variant="contained"
                sx={{ px: 2 }}
                onClick={() =>
                  setConfirmationModalAtom({
                    message: "¿Está seguro que desea crear un nuevo documento?",
                    subMessage:
                      "Esto borrará cualquier progreso no guardado del documento actual y comenzará un nuevo documento desde cero.",
                    show: true,
                    onConfirm: () => {
                      deleteAndRedirect(history);
                    },
                  })
                }
              >
                <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faPen} />
                Crear nuevo
              </Button>
            </Box>
          </Box>
          <div className="dashboard-selector">
            <div className="dashboard-selector-grid">
              {displayCompanyDocuments &&
                displayCompanyDocuments.length > 0 &&
                displayCompanyDocuments.map((doc, index) => {
                  return (
                    <Box display={"flex"} gap={2} width={"100%"} key={index}>
                      <DocumentCard
                        documentName={
                          doc.documentName || "Template de Andes Docs"
                        }
                        onClick={() => {
                          localStorage.setItem(
                            "document-update-data",
                            JSON.stringify(doc)
                          );
                          localStorage.setItem(
                            "removeLogo",
                            String(doc.removeLogo)
                          );
                          getDocumentData(
                            doc.dataPath,
                            doc.generatorVersion,
                            doc.sheetId
                          );
                        }}
                        index={index}
                        logo={companyLogo}
                        isNewlyDuplicated={doc.isNewlyDuplicated}
                      />
                      <Button
                        variant="text"
                        sx={{
                          px: 2,
                          color: colorPallette.dark,
                          height: "65px",
                        }}
                        onClick={(event) => handleMenuOpen(event, doc)}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: "20px" }}
                          icon={faEllipsisV}
                        />
                      </Button>
                    </Box>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      {/* Esto mas adelante lo podemos hacer un componente reutilizable */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: `${colorPallette.secondary} !important`,
          },
        }}
      >
        <MenuItem
          onClick={handleDuplicateDocument}
          sx={{
            backgroundColor: `${colorPallette.secondary} !important`,
            color: colorPallette.dark,
            fontSize: "0.9rem",
          }}
        >
          <FontAwesomeIcon icon={faCopy} style={{ marginRight: "8px" }} />
          Duplicar
        </MenuItem>
        <MenuItem
          onClick={handleDeleteDocument}
          sx={{
            backgroundColor: `${colorPallette.secondary} !important`,
            color: colorPallette.dark,
            fontSize: "0.9rem",
          }}
        >
          <FontAwesomeIcon icon={faTrash} style={{ marginRight: "8px" }} />
          Eliminar
        </MenuItem>
      </Menu>
    </div>
  );
};

export default DashboardEditSelector;
