import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "../../redux/store";
import { User } from "../../Types/User.types";
import CONSTANTS from "../../Common/constants";

type BarElementProps = {
  element: {
    name: string;
    to: string;
    icon: string;
  };
  index: number;
  selected: number;
  setSelected: (index: number) => void;
};

export const BarElement: React.FC<BarElementProps> = ({
  element,
  index,
  selected,
  setSelected,
}) => {
  const { internalUser: isInternalUser, role } = useSelector(
    (state: RootState) => state.masterData.user as User
  );

  const isSelected = index === selected;

  const history = useHistory();

  if (
    element.name === "Editor" &&
    !isInternalUser &&
    role !== CONSTANTS.ROLES.USER_EDITOR &&
    role !== CONSTANTS.ROLES.ADMIN_EDITOR
  ) {
    return null;
  }

  if (role === CONSTANTS.ROLES.USER && element.name === "Control de Usuarios") {
    return null;
  }

  return (
    <div
      className={`left-bar-element left-bar-element-selected-${isSelected} ${
        isSelected && "b-secondary"
      }`}
      onClick={() => {
        setSelected(index);
        history.push(element.to);
      }}
    >
      <div
        className={`left-bar-element-content left-bar-element-content-selected-${isSelected}`}
      >
        <div className="left-bar-element-icon-box">
          <i className={`${element.icon}`} />
        </div>
        <div className="left-bar-name">{element.name}</div>
      </div>
    </div>
  );
};
