import React from "react";
import { useHistory } from "react-router-dom";
import Image from "../../../uiComponents/Image/Image";
import "./styles.css";

type DocumentCardProps = {
  title1?: string;
  title2?: string;
  onClick?: () => void;
  index?: number;
  logo?: any;
  to?: string;
  price?: string;
  isNewlyDuplicated?: boolean;
  documentName?: string;
};

const DocumentCard: React.FC<DocumentCardProps> = ({
  title1,
  title2,
  to,
  onClick,
  logo,
  isNewlyDuplicated,
  documentName,
}) => {
  const splitDocumentName = (name: string) => {
    const words = name.split(" ");
    if (words.length <= 3) {
      return {
        title: words.slice(0, -1).join(" "),
        subtitle: words.slice(-1).join(" "),
      };
    } else {
      return {
        title: words.slice(0, -2).join(" "),
        subtitle: words.slice(-2).join(" "),
      };
    }
  };

  const { title, subtitle } = splitDocumentName(
    documentName ?? "Template de Andes Docs"
  );

  let history = useHistory();

  const handleClick = () => {
    if (to) {
      history.push(`/${to}`);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={`document-card document-card-available ${
        isNewlyDuplicated ? "new-duplicate" : ""
      }`}
      onClick={handleClick}
    >
      <div className="document-card-start-div">
        <p className="document-card-text">{title}</p>
        <p className="document-card-text-bold">{subtitle}</p>
      </div>
      <div
        className={`logo-background b-white ${
          isNewlyDuplicated ? "fade-in" : ""
        }`}
      >
        {logo ? (
          <img
            src={logo}
            className="document-card-logo-company"
            alt="Company logo"
            title="Logo de la empresa"
          />
        ) : (
          <Image name={"andesdocs.png"} className="document-card-logo" />
        )}
      </div>
    </div>
  );
};

DocumentCard.defaultProps = {
  title1: "Acuerdo",
  title2: "de confidencialidad",
};

export default DocumentCard;
