import React, { useEffect, useState } from "react";
import NewElectronicSignatureScreen from "./NewElectronicSignatureScreen/NewElectronicSignatureScreen";
import ElectronicSignatureForm from "./ElectronicSignatureForm/ElectronicSignatureForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setShouldESign } from "../../../redux/slices/EditorDataSlice";

import "./styles.css";
import { AppDispatch, RootState } from "../../../redux/store";
import { Button } from "@mui/material";
import { colorPallette } from "../../../Common/theme";
import { Document } from "../../../jotai/documents.jotai";

type ElectronicSignatureProps = {
  documents: Document[];
  company: string;
};

const ElectronicSignature: React.FC<ElectronicSignatureProps> = ({
  documents,
  company,
}) => {
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [currentSignDocument, setCurrentSignDocument] =
    useState<Document | null>(null);

  const dispatch: AppDispatch = useDispatch();
  const { document, shouldESign } = useSelector(
    (state: RootState) => state.editorData
  );

  const setEsignDocumentData = (document: Document) => {
    setCurrentSignDocument(document as Document);
    setCurrentSection(1);
  };

  useEffect(() => {
    if (shouldESign) {
      dispatch(setShouldESign(false));
      setEsignDocumentData(document);
    }
  }, []);

  return (
    <div className="electronic-signature-wrapper animate">
      {currentSection === 0 && (
        <NewElectronicSignatureScreen
          documents={documents}
          setEsignDocumentData={setEsignDocumentData}
          company={company}
        />
      )}
      {currentSection === 1 && (
        <Button
          onClick={() => {
            setCurrentSection(0);
            setCurrentSignDocument(null);
          }}
          endIcon={<FontAwesomeIcon icon={faTimes} className="primary" />}
          sx={{
            position: "absolute",
            top: 20,
            right: 100,
            color: colorPallette.dark,
          }}
        >
          Cancelar
        </Button>
      )}
      {currentSection === 1 && currentSignDocument && (
        <ElectronicSignatureForm document={currentSignDocument} />
      )}
    </div>
  );
};

export default ElectronicSignature;
