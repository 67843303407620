import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getOrCreateUser } from "../../Utils/getMasterData";
import { setLoading } from "../../Utils/modalMethods";
import CONSTANTS from "../../Common/constants";

const currentUrl =
  process.env.REACT_APP_ENV === "development"
    ? "http://localhost:3000"
    : "https://andesdocs.com";

const LoginRedirect = () => {
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [retries, setRetries] = useState(0);
  let history = useHistory();

  const userCreationOrGet = async () => {
    const accesToken = await getAccessTokenSilently();
    const gotUser = await getOrCreateUser(accesToken, user);
    return gotUser;
  };

  // Casos posibles:
  // 1 -> Loguearse con google y company id 00000
  // 2 -> loguearse con google y company id correcto
  // 3 -> loguearse con google y company id 99999
  // 4 -> Loguearse con mail pero todavia no verifico su mail
  // 5 -> Loguearse con mail, verifico mail y comapny id 00000
  // 6 -> Loguearse con mail, verifico mail y comapny id correcto
  // 7 -> Loguearse con mail, verifico mail y comapny id 99999
  // 8 -> Se loguea con mail pero ya tenia Google
  // 9 -> Se loguea con google pero ya tenia email

  // This function is used to redirect the user to the correct page after login
  const handleRedirectFromLogIn = async () => {
    if (retries > 3) {
      history.push(CONSTANTS.ROUTES.HOME);
      return;
    }
    try {
      setLoading(true);
      if (!user || !isAuthenticated) {
        return;
      }

      const dynamoDBUser = await userCreationOrGet();

      if (!dynamoDBUser) {
        // case 2 -> user is authenticated but he has a previous user with same email
        // 8 -> Se loguea con mail pero ya tenia Google
        // 9 -> Se loguea con google pero ya tenia email

        logout({
          returnTo: `${currentUrl}${CONSTANTS.ROUTES.PREV_USER_EXISTANT}`,
        });
        return;
      }

      // if (user.email_verified === false) {
      //   // case 1 -> user is authenticated but email is not verified -> This is because he used email and password authentication
      //   // There is a chance that he did not verify this email

      //   // 4 -> Loguearse con mail pero todavia no verifico su mail
      //   history.push(CONSTANTS.ROUTES.EMAIL_VERIFICATION);
      //   return;
      // }

      // redeploy

      if (dynamoDBUser === "config") {
        // case 3 -> user is created or exists but still has not been asigned to a company
        // this means company id === 00000

        // 1 -> Loguearse con google y company id 00000
        // 3 -> loguearse con google y company id 99999
        // 5 -> Loguearse con mail, verifico mail y comapny id 00000
        // 7 -> Loguearse con mail, verifico mail y comapny id 99999

        logout({ returnTo: `${currentUrl}${CONSTANTS.ROUTES.SUPPORT_CONFIG}` });
        return;
      }

      if (dynamoDBUser) {
        // 2 -> loguearse con google y company id correcto
        // 6 -> Loguearse con mail, verifico mail y comapny id correcto
        history.push(`${CONSTANTS.ROUTES.DASHBOARD}/finished`);
        return;
      }

      setRetries((prev) => prev + 1);
    } catch (error) {
      setRetries((prev) => prev + 1);
    }
  };

  useEffect(() => {
    handleRedirectFromLogIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, user, retries]);

  return <></>;
};

export default LoginRedirect;
