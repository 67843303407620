import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { setLoading } from "../../../Utils/modalMethods";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { colorPallette } from "../../../Common/theme";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { useAtomValue } from "jotai";
import {
  commonComponentAtom,
  resetCommonComponent,
} from "../../../jotai/commonComponentAtom.jotai";
import CONSTANTS from "../../../Common/constants";

const roleOptions = [
  { value: CONSTANTS.ROLES.ADMIN, label: "Administrador" },
  { value: CONSTANTS.ROLES.USER, label: "Usuario básico" },
  {
    value: CONSTANTS.ROLES.USER_EDITOR,
    label: "Usuario y Editor de Plantillas (avanzado)",
  },
  {
    value: CONSTANTS.ROLES.ADMIN_EDITOR,
    label: "Administrador y Editor de Plantillas (avanzado)",
  },
];

const documentShareOptions = [
  { value: "true", label: "Todos los documentos de la empresa" },
  { value: "false", label: "Solo los documentos del usuario" },
];

const EditUserModal: React.FC = () => {
  const { currentUser, onSave } = useAtomValue(commonComponentAtom);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      role: currentUser?.role,
      documentShare: currentUser?.documentShare ? "true" : "false",
    },
  });

  const handleSave = async (data: any) => {
    setLoading(true);
    onSave({
      ...currentUser,
      role: data.role,
      documentShare: data.documentShare === "true",
    });
    setLoading(false);
  };

  const fields: Field[] = [
    {
      name: "role",
      label: "Rol",
      type: "options",
      required: true,
      options: roleOptions,
    },
    {
      name: "documentShare",
      label: "Acceso a",
      type: "options",
      required: true,
      options: documentShareOptions,
    },
  ];

  const handleClose = () => {
    resetCommonComponent();
  };

  return (
    <AndesModal
      message="Editar usuario"
      handleClose={handleClose}
      handleSubmit={handleSubmit(handleSave)}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          my: 2,
          fontSize: "1rem",
        }}
      >
        <FontAwesomeIcon
          icon={faUserAlt}
          style={{ color: colorPallette.darkSecondary, marginRight: 8 }}
        />
        {currentUser?.name}
      </Box>
      <AutomaticForm fields={fields} control={control} errors={errors} />
    </AndesModal>
  );
};

export default EditUserModal;
