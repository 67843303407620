import { Box } from "@mui/material";
import React from "react";
import { TutorialVideo } from "./TutorialVideo";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import TutorialPdfViewer from "./TutorialPdfViewer";
import pdfFirmaElectronica from "./InstructivoFirmaElectronicaAndesDocs.pdf";

const Tutorials: React.FC = () => {
  const videoTutorials = [
    {
      title: "Generación de Contrato en Andes Docs",
      icon: "file-pen",
      src: "https://www.loom.com/embed/c54fb659c4d74d0180af4e6aed7b1f1b?sid=953380a9-d4b8-451b-954b-dab55f514a46",
      message: "En este video aprenderás a crear contratos automatizados:",
    },
    {
      title: "Generación de Firma Electrónica en Andes Docs",
      icon: "signature",
      src: "https://www.loom.com/embed/267792d45edd49a9ba4ff1311046d08c?sid=bf476e85-67de-4fa0-a2aa-91fd4b1df6d7",
      message:
        "En este video aprenderás a como firmar electrónicamente contratos automatizados:",
    },
    {
      title: "Creación de Usuario en Andes Docs",
      icon: "user",
      src: "https://www.loom.com/embed/a21ed9f0c30943c891cee660105e71e0?sid=61479830-15fa-4976-b4c9-586293525a92",
      message: "En este video aprenderás a registrarte como usuario:",
    },
  ];

  const pdfTutorials = [
    {
      title: "Cómo hacer una Firma Electrónica en Andes Docs",
      icon: faPlayCircle,
      src: pdfFirmaElectronica,
      message:
        "En este documento aprenderás a como firmar electrónicamente contratos automatizados:",
    },
  ];

  return (
    <Box
      sx={{
        pt: 5,
        display: "flex",
        gap: "100px",
        flexDirection: "column",
        maxWidth: "600px",
      }}
      className="animate"
    >
      {videoTutorials.map((tutorial, index) => (
        <TutorialVideo
          key={index}
          title={tutorial.title}
          icon={tutorial.icon}
          message={tutorial.message}
          src={tutorial.src}
        />
      ))}
      {pdfTutorials.map((tutorial, index) => (
        <TutorialPdfViewer
          key={index}
          title={tutorial.title}
          icon={tutorial.icon}
          message={tutorial.message}
          src={tutorial.src}
        />
      ))}
    </Box>
  );
};

export default Tutorials;
