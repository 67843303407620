import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../../uiComponents/Button/Button";
import CONSTANTS from "../../Common/constants";

const EmailVerification = () => {
  const { isAuthenticated, user } = useAuth0();
  let history = useHistory();

  useEffect(() => {
    if (isAuthenticated && user?.email_verified) {
      history.push(CONSTANTS.ROUTES.LOGIN_REDIRECT);
    }
    if (!isAuthenticated) {
      history.push(CONSTANTS.ROUTES.HOME);
    }
  }, [user, isAuthenticated]);

  return (
    <div style={{ padding: 70 }}>
      {user && (
        <p className="margin-v grey">
          Hemos enviado un mail a su casilla de correo:
          <span className="primary"> {user.email} </span>
        </p>
      )}
      <p className="margin-v">
        Por favor ingrese y verifique su email para continuar
      </p>
      <Button
        label="Ya lo verifiqué"
        onClick={() => {
          history.push(CONSTANTS.ROUTES.DASHBOARD);
          window.location.reload();
        }}
        color="secondary"
        center
      />
    </div>
  );
};

export default EmailVerification;
