import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPost } from "../../../Services/authenticated";
import { Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { resetCommonComponent } from "../../../jotai/commonComponentAtom.jotai";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { useDispatch, useSelector } from "react-redux";
import { setFolders } from "../../../redux/slices/masterData";
import { setLoading } from "../../../Utils/modalMethods";
import { RootState } from "../../../redux/rootReducer";

const NewFolderModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { folders } = useSelector((state: RootState) => state.masterData);

  const fields: Field[] = [
    {
      name: "folderName",
      label: "Nombre de la carpeta",
      type: "text",
      required: true,
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { folderName: "" },
  });

  const dispatch = useDispatch();

  const onSaveFolder = async (data: any) => {
    const folderName = data.folderName;
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const res = await APIPost("/folders/create-folder", accessToken, {
        folderName,
      });

      if (res.folderSaved && res.folder) {
        resetCommonComponent();
        const currentFolders = folders || [];
        dispatch(setFolders([res.folder, ...currentFolders]));
      }
    } catch (e) {
      console.error("Error al crear la carpeta:", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AndesModal
      message="Nueva Carpeta"
      handleClose={resetCommonComponent}
      handleSubmit={handleSubmit(onSaveFolder)}
      submitButtonText="Crear carpeta"
    >
      <Stack mt={2} width={"100%"}>
        <AutomaticForm fields={fields} control={control} errors={errors} />
      </Stack>
    </AndesModal>
  );
};

export default NewFolderModal;
