import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import { Question } from "../../Types/Question.types";
import { useHistory } from "react-router-dom";
import GeneratorQuestion from "./GeneratorQuestion";
import styles from "./GeneratorForm.module.css";
import FormInput from "../../uiComponents/Form/FormInput";
import GeneratorFormSections from "./GeneratorFormSections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../Common/theme";
import ReviewSection from "./ReviewSection";
import InternalGeneratorTaskBar from "../InternalGenerator/InternalGeneratorTaskBar";
import { checkDependencies } from "../../Utils/FormMethods";
import { setAppError } from "../../Utils/modalMethods";
import CONSTANTS from "../../Common/constants";

const GeneratorForm = () => {
  const [documentQuestions, setDocumentQuestions] = useState<Question[]>([]);
  const [documentKind, setDocumentKind] = useState<string>("");
  const [currentSection, setCurrentSection] = useState(1);
  const [isReview, setIsReview] = useState(false);

  const { control, handleSubmit, watch, setValue, unregister } = useForm<any>({
    defaultValues: JSON.parse(
      localStorage.getItem("generator-form-answers") || "[]"
    ) || {
      questions: [],
    },
  });

  const formWatch = watch();
  const history = useHistory();

  useEffect(() => {
    const data = localStorage.getItem("generator-form-data");

    if (data) {
      const parsedData = JSON.parse(data);

      setDocumentQuestions(parsedData.questions);

      const documentType = localStorage.getItem("selectedDocumentKind");

      if (documentType) {
        setDocumentKind(documentType);
      }

      return;
    } else {
      history.push(CONSTANTS.ROUTES.HOME);
    }
  }, [history]);

  const lsSections = JSON.parse(
    localStorage.getItem("generator-form-sections") || "{}"
  );

  const onPrevious = () => {
    if (currentSection === 1) return;
    setCurrentSection((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  const onSubmit = (data: any) => {
    const previousAnswers = JSON.parse(
      localStorage.getItem("generator-form-answers") || "{}"
    );

    const currentSectionQuestionIds = documentQuestions
      .filter((question) => question.section === String(currentSection))
      .map((question) => String(question.id));

    const filteredPrevData: any = {};

    Object.keys(previousAnswers).forEach((key) => {
      if (!currentSectionQuestionIds.includes(String(key))) {
        filteredPrevData[key] = data[key];
      }
    });

    localStorage.setItem(
      "generator-form-answers",
      JSON.stringify({ ...filteredPrevData, ...data })
    );
    if (Object.keys(lsSections).includes(String(currentSection + 1))) {
      setCurrentSection((prev) => prev + 1);
      window.scrollTo(0, 0);
    } else {
      setIsReview(true);
      window.scrollTo(0, 0);
    }
  };

  const isInternalTesting = window.location.href.includes("/testing");

  useEffect(() => {
    Object.keys(formWatch).forEach((key) => {
      const currentQuestion = documentQuestions.find((question) => {
        return String(question.id) === String(key);
      });

      if (
        currentQuestion &&
        !checkDependencies(currentQuestion as Question, formWatch)
      ) {
        unregister(key);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formWatch]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="380px 1fr"
      sx={{
        "& input": {
          color: colorPallette.dark,
        },
      }}
    >
      <GeneratorFormSections
        currentSection={currentSection}
        watch={watch}
        isReview={isReview}
      />
      {!isReview && (
        <Box width={"100%"}>
          <Stack
            minHeight="100vh"
            alignItems="center"
            className="b-tertiary"
            p={4}
          >
            <Stack
              direction="row"
              width="100%"
              textAlign="left"
              maxWidth={1000}
              my={5}
              pl={2}
              fontWeight="Bold"
              fontSize={23}
              alignItems="center"
            >
              <FontAwesomeIcon
                icon={faPlayCircle}
                style={{
                  color: colorPallette.darkSecondary,
                  marginRight: 10,
                }}
              />
              <Stack sx={{ transform: "translateY(-8px)" }}>
                <Box fontWeight={400} fontSize={14} mb={"-2px"}>
                  Sección {currentSection}
                </Box>
                {lsSections?.[currentSection] ||
                  `Formulario de generación de documento`}
              </Stack>
            </Stack>
            {currentSection === 1 && (
              <Box className={styles.card} maxWidth="1000px">
                <FormInput
                  label="Nombre del documento:"
                  name="documentName"
                  control={control}
                  required
                  info={[
                    "Este va a ser el nombre del documento que se va a generar",
                    "Por ejemplo: Contrato de alquiler Ricardo Darín",
                  ]}
                />
              </Box>
            )}
            {documentQuestions
              .filter((question) => question.section === String(currentSection))
              // .filter((question) => checkDependencies(question, getValues))
              .map((question, index) => (
                <GeneratorQuestion
                  question={question}
                  control={control}
                  watch={watch}
                  key={question.id}
                  setValue={setValue}
                />
              ))}
            <Stack
              direction="row"
              width="100%"
              maxWidth="1000px"
              justifyContent="space-between"
              my={2}
              mb={8}
            >
              <Button
                onClick={() => {
                  onPrevious();
                }}
                sx={{
                  width: 250,
                  mr: 2,
                  backgroundColor: colorPallette.lightRed,
                  color: colorPallette.white,
                }}
                variant="contained"
              >
                <Box component="span" mr={1}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </Box>
                Anterior
              </Button>
              <Button
                onClick={() => {
                  handleSubmit(onSubmit, () => {
                    setAppError(
                      "Debe completar todos los campos",
                      "Revise los campos marcados en rojo"
                    );
                  })();
                }}
                sx={{ width: 250 }}
                variant="contained"
              >
                Siguiente
                <Box component="span" ml={1}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Box>
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
      {isReview && (
        <ReviewSection
          setIsReview={setIsReview}
          documentQuestions={documentQuestions}
          documentKind={documentKind}
        />
      )}
      {isInternalTesting && (
        <InternalGeneratorTaskBar onSave={() => handleSubmit(onSubmit)()} />
      )}
    </Box>
  );
};

export default GeneratorForm;
