import React from "react";
import "./styles.css";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import { useAtomValue } from "jotai";
import {
  commonComponentAtom,
  resetCommonComponent,
} from "../../../jotai/commonComponentAtom.jotai";

const SuccessModal: React.FC = () => {
  const { message, subMessage, disclaimer } = useAtomValue(commonComponentAtom);

  return (
    <AndesModal
      message={message || "Acción realizada exitosamente"}
      subMessage={subMessage || ""}
      disclaimer={disclaimer || ""}
      handleConfirm={resetCommonComponent}
      confirmButtonText="Entendido"
    />
  );
};

export default SuccessModal;
