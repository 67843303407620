import { atom } from "jotai";
import { getDefaultStore } from "jotai";
import { NewSigner } from "../Types/Signer.types";
import { ElectronicSignature } from "./electronicSignatures.jotai";
import { User } from "../Types/User.types";

type Folder = {
  folderName: string;
  folderId: string | number;
};

type CommonComponentAtomType = {
  // Data
  currentDocument: any;
  currentSignature: ElectronicSignature | null;
  currentSigner: NewSigner | null;
  currentFolder: Folder | null;
  currentUser: User | null;
  onSave: any;

  // Modals
  showNewFolderModal: boolean;
  showFolderModal: boolean;
  showDocumentDetailModal: boolean;
  showLoadingScreen: boolean;
  showSignatureStatusModal: boolean;
  showSignerEmailModal: boolean;
  showFileUploadModal: boolean;
  showExpirationModal: boolean;
  showFolderNameModal: boolean;
  showAlarmModal: boolean;
  showStatusModal: boolean;
  showEditProfileNameModal: boolean;
  showErrorModal: boolean;
  showSuccessModal: boolean;
  showEditUserModal: boolean;
  showEditUserModalDeIn: boolean;
  showAddNewSignerModal: boolean;

  // Messages
  message: string;
  subMessage: string;
  disclaimer: string;
};

export const initialCommonComponentAtom: CommonComponentAtomType = {
  // Data
  currentDocument: null,
  currentSignature: null,
  currentSigner: null,
  currentFolder: null,
  currentUser: null,
  onSave: () => {},

  // Modals
  showNewFolderModal: false,
  showFolderModal: false,
  showDocumentDetailModal: false,
  showLoadingScreen: false,
  showExpirationModal: false,
  showSignatureStatusModal: false,
  showSignerEmailModal: false,
  showFileUploadModal: false,
  showFolderNameModal: false,
  showAlarmModal: false,
  showStatusModal: false,
  showErrorModal: false,
  showSuccessModal: false,
  showEditProfileNameModal: false,
  showEditUserModal: false,
  showEditUserModalDeIn: false,
  showAddNewSignerModal: false,

  // Messages
  message: "",
  subMessage: "",
  disclaimer: "",
};

export const commonComponentAtom = atom<CommonComponentAtomType>(
  initialCommonComponentAtom
);

const store = getDefaultStore();

export const resetCommonComponent = () => {
  store.set(commonComponentAtom, initialCommonComponentAtom);
};

export const setCommonComponents = (params: any) => {
  store.set(commonComponentAtom, { ...initialCommonComponentAtom, ...params });
};

type LoadingAtomType = {
  showLoadingModal: boolean;
};

export const initialLoadingModalAtom = {
  showLoadingModal: false,
};

export const loadingAtom = atom<LoadingAtomType>(initialLoadingModalAtom);

export const setLoadingAtom = (param: boolean) => {
  store.set(loadingAtom, { showLoadingModal: param });
};
