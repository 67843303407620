import { AlignmentType, Footer, Header, ImageRun, Paragraph } from "docx";
import { cadema_logo } from "../docImages/cadema";

export const styles_cadema = {
  companyId: ["12415"],
  companyName: "Cadema",
  font: "Calibri",
  titleSize: 40,
  textSize: 22,
  lineSpacing: 200,
  marginRight: 900,
  marginLeft: 900,
  beforeParagraph: 200,

  header: new Header({
    children: [
      // Esta configuración queda comentada para futuro estilo de cadema en los docs
      // para cuando tengamos la posibilidad de aplicar estilos a archivos especificos.
      // Primer elemento:
      // new Paragraph({
      //   spacing: {
      //     before: 0,
      //     after: 500,
      //   },
      //   alignment: AlignmentType.CENTER,
      //   children: [
      //     new ImageRun({
      //       data: header_cadema,
      //       transformation: {
      //         width: 815,
      //         height: 120,
      //       },
      //       floating: {
      //         horizontalPosition: {
      //           offset: 0,
      //         },
      //         verticalPosition: {
      //           offset: 0,
      //         },
      //       },
      //     }),
      //   ],
      // }),
      // Segundo elemento:
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: cadema_logo,
            transformation: {
              width: 350,
              height: 350,
            },
            floating: {
              horizontalPosition: {
                offset: 2000000,
              },
              verticalPosition: {
                offset: 3000000,
              },
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      // Esta configuración queda comentada para futuro estilo de cadema en los docs
      // para cuando tengamos la posibilidad de aplicar estilos a archivos especificos.
      // new Paragraph({
      //   spacing: {
      //     before: 0,
      //     after: 550,
      //   },
      //   alignment: AlignmentType.CENTER,
      //   children: [
      //     new ImageRun({
      //       data: footer_cadema,
      //       transformation: {
      //         width: 815,
      //         height: 100,
      //       },
      //       floating: {
      //         horizontalPosition: {
      //           offset: 0,
      //         },
      //         verticalPosition: {
      //           offset: 0,
      //         },
      //       },
      //     }),
      //   ],
      // }),
    ],
  }),
};
