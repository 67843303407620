import React, { useEffect, useState, ComponentType } from "react";

import { setAppError, setLoading } from "../../Utils/modalMethods";
import { APIGetWithError } from "../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, useHistory } from "react-router-dom";
import { User } from "../../Types/User.types";
import CONSTANTS from "../../Common/constants";

interface WithRoleProps {
  ExternalUserComponent: ComponentType;
  InternalUserComponent: ComponentType;
}

const EditorCheckWrapper: React.FC<WithRoleProps> = ({
  ExternalUserComponent,
  InternalUserComponent,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const token = await getAccessTokenSilently();
        const response = await APIGetWithError("/users/get-user-role", token);

        if (response.user) {
          setUser(response.user);
        } else {
          history.push(CONSTANTS.ROUTES.HOME);
          setAppError(
            "Usted no tiene los permisos requeridos",
            "Contáctese con soporte"
          );
        }
      } catch (err) {
        history.push(CONSTANTS.ROUTES.HOME);
        setAppError(
          "Usted no tiene los permisos requeridos",
          "Contáctese con soporte"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  if (!user) return <div>Cargando...</div>;

  if (user?.internalUser) {
    return <InternalUserComponent />;
  }

  if (
    user?.role === CONSTANTS.ROLES.ADMIN_EDITOR ||
    user?.role === CONSTANTS.ROLES.USER_EDITOR
  ) {
    return <ExternalUserComponent />;
  }
  return <Redirect to={CONSTANTS.ROUTES.HOME} />;
};

export default EditorCheckWrapper;
