import React, { useMemo } from "react";
import { MRT_ColumnDef } from "material-react-table";
import {
  faTrashAlt,
  faEdit,
  faUser,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import { Box, Typography } from "@mui/material";
import { colorPallette } from "../../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { setConfirmationModalAtom } from "../../../jotai/confimationModalAtom.jotai";
import { ProfilePicAndName } from "../../../uiComponents/ProfilePicAndName/ProfilePicAndName";
import CONSTANTS from "../../../Common/constants";

type Props = {
  documents: any[];
  onEditUser: (user: any) => void;
  onDeleteUser: (user: any) => void;
};

const UsersManagementTableDeIn: React.FC<Props> = ({
  documents,
  onEditUser,
  onDeleteUser,
}) => {
  const userTableActions: TableAction[] = [
    {
      name: "Editar",
      icon: faEdit,
      onClickWithParams: (data: any) => onEditUser(data),
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      color: colorPallette.primary,
      onClickWithParams: (data: any) => {
        if (data.userId) {
          setConfirmationModalAtom({
            message: "¿Estás seguro que quieres eliminar este usuario?",
            subMessage: `Usuario: ${data.email}`,
            show: true,
            onConfirm: () => {
              onDeleteUser(data.userId);
            },
          });
        }
      },
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          return (
            <ProfilePicAndName
              image={cell.row.original.photoUrl}
              name={cell.getValue()}
            />
          );
        },
        maxSize: 300,
        minSize: 250,
      },
      {
        accessorKey: "email",
        header: "Email",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 250,
        Cell: ({ cell }: any) => {
          return (
            <Typography fontWeight={"regular"} fontSize={"0.8rem"}>
              {cell.getValue()}
            </Typography>
          );
        },
      },
      {
        accessorKey: "role",
        header: "Rol",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 200,
        Cell: ({ cell }: any) => {
          const role = cell.getValue();
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "0.8rem",
                color:
                  role === CONSTANTS.ROLES.ADMIN
                    ? colorPallette.primary
                    : colorPallette.dark,
              }}
            >
              {role === CONSTANTS.ROLES.ADMIN && (
                <>
                  <FontAwesomeIcon
                    style={{ color: colorPallette.darkSecondary }}
                    icon={faUserTie}
                    fontSize={12}
                  />
                  Administrador
                </>
              )}
              {role === CONSTANTS.ROLES.USER && (
                <>
                  <FontAwesomeIcon
                    style={{ color: colorPallette.darkSecondary }}
                    icon={faUser}
                    fontSize={12}
                  />
                  Usuario básico
                </>
              )}
              {role === CONSTANTS.ROLES.USER_EDITOR && (
                <>
                  <FontAwesomeIcon
                    style={{ color: colorPallette.darkSecondary }}
                    icon={faUser}
                    fontSize={12}
                  />
                  Usuario y Editor de Plantillas
                </>
              )}
              {role === CONSTANTS.ROLES.ADMIN_EDITOR && (
                <>
                  <FontAwesomeIcon
                    style={{ color: colorPallette.darkSecondary }}
                    icon={faUserTie}
                    fontSize={12}
                  />
                  Administrador y Editor de Plantillas
                </>
              )}
            </Box>
          );
        },
      },

      {
        accessorKey: "documentShare",
        header: "Acceso",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 250,
        Cell: ({ cell }: any) => {
          const documentShare = cell.getValue();
          return (
            <Box
              sx={{
                color: documentShare
                  ? colorPallette.primary
                  : colorPallette.dark,
                fontSize: "0.7rem",
              }}
            >
              {documentShare
                ? "Todos los documentos de la empresa"
                : "Solo los documentos del usuario"}
            </Box>
          );
        },
      },
      {
        accessorKey: "companyName",
        header: "Empresa",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 150,
        Cell: ({ cell }: any) => {
          return (
            <Typography
              color={colorPallette.primary}
              variant="body2"
              fontSize="0.7rem"
            >
              {cell.getValue()}
            </Typography>
          );
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 200,
        Cell: ({ cell }: any) => (
          <TableActions
            actions={userTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <AndesTable
        documents={documents}
        columns={columns}
        initialSort={[{ id: "name", desc: false }]}
      />
    </>
  );
};

export default UsersManagementTableDeIn;
