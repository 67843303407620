import React from "react";
import Button from "../../../../../uiComponents/Button/Button";
import Text from "../../../../../uiComponents/Text";
import { connect } from "react-redux";
import "./styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import XsButton from "../../../../../uiComponents/XsButton/XsButton";
import CONSTANTS from "../../../../../Common/constants";

type CheckSectionProps = {
  data: any;
  answers: any;
  setCurrentSection: any;
  resetDocumentToStart: any;
};

const CheckSection: React.FC<CheckSectionProps> = ({
  data,
  answers,
  setCurrentSection,
  resetDocumentToStart,
}) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();

  const preArray = Object.keys(answers);
  const sectionsArray = preArray.splice(2);
  const finalArray = sectionsArray.map((section, index) => {
    return {
      section: section,
      questions: Object.keys(answers[section]),
      title: data.sections[index].section,
    };
  });

  const onContinue = () => {
    if (isAuthenticated) {
      console.log(
        window.location.pathname,
        window.location.pathname.includes(
          CONSTANTS.ROUTES.COMPANY_DOCUMENT_CREATOR
        )
      );
      if (
        window.location.pathname.includes(
          CONSTANTS.ROUTES.COMPANY_DOCUMENT_CREATOR
        )
      ) {
        history.push(CONSTANTS.ROUTES.COMPANY_DOCUMENT_DOWNLOAD);
        return;
      }
      history.push("/company-document-download/andes-library");
    }
    // } else {
    //   setCheckStage(1);
    // }
  };

  return (
    <div className="check-section-wrapper">
      <div>
        <Text
          roboto
          size="large"
          align="center"
          className="margin-v check-section-instructions"
        >
          Controla tus respuestas!
        </Text>
        <Text
          size="medium"
          align="center"
          className="width80 center check-section-instructions"
        >
          Si cometiste algún error podes corregirlo!
        </Text>
        <Text size="small" align="center" grey className="margin-v">
          *Si estas seguro podes continuar:
        </Text>
        <Button
          label="¡Generar Documento Ahora!"
          className="margin-v"
          center
          onClick={onContinue}
          size="large"
          color="secondary"
        />
        <XsButton
          label="Reiniciar"
          className="margin-v center"
          onClick={() => {
            resetDocumentToStart();
            window.location.reload();
          }}
          color="red"
        />
        <div>
          {finalArray &&
            finalArray.map((section, index) => {
              return (
                <div className="checker-section-card center">
                  <Text
                    size="small"
                    align="center"
                    className="check-section-section primary"
                  >
                    Sección {index + 1}
                  </Text>
                  <Text
                    align="center"
                    roboto
                    size="large"
                    className="check-section-title"
                  >
                    {section.title}
                  </Text>

                  <div className="margin-v width100">
                    {section.questions.map((question) => {
                      return (
                        <div style={{ textAlign: "left" }} className="width100">
                          <p className="margin-v">
                            {answers[section.section][question].question}
                          </p>
                          <p className="roboto-b primary">
                            {answers[section.section][question].answer?.label
                              ? answers[section.section][question].answer?.label
                              : answers[section.section][question].answer}
                          </p>
                          <div className="checker-section-divider center" />
                        </div>
                      );
                    })}
                    <div
                      className="checker-section-editor center b-secondary b-secondary-hover"
                      onClick={() => {
                        setCurrentSection(index + 1);
                      }}
                    >
                      Editar
                      <i className="fas fa-pencil-alt"></i>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <Button
          label="¡Generar Documento!"
          className="margin-v-l"
          center
          size="large"
          color="secondary"
          onClick={onContinue}
        />
        <XsButton
          label="Reiniciar"
          className="center margin-b"
          onClick={() => {
            resetDocumentToStart();
            window.location.reload();
          }}
          color="red"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps)(CheckSection);
