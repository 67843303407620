import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useForm } from "react-hook-form";
import { useAtomValue } from "jotai";
import {
  commonComponentAtom,
  resetCommonComponent,
  setCommonComponents,
} from "../../../jotai/commonComponentAtom.jotai";
import { updateDocumentOnAtom } from "../../../jotai/documents.jotai";
import { APIPostWithError } from "../../../Services/authenticated";
import AndesModal from "../../../uiComponents/AndesModal/AndesModal";
import AutomaticForm, { Field } from "../../../uiComponents/Form/AutomaticForm";
import { Box } from "@mui/material";
import FormLabel from "../../../uiComponents/Form/FormLabel";
import FormDisplay from "../../../uiComponents/Form/FormDisplay";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import CreatorField from "./CreatorField/CreatorField";
import { categories } from "../../../Common/constants";

const DocumentDetailModal: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { currentDocument } = useAtomValue(commonComponentAtom);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fileName: currentDocument?.fileName || "",
      description: currentDocument?.description || "",
      documentKind: currentDocument?.documentKind || "Sin definir",
    },
  });

  const fields: Field[] = [
    {
      name: "fileName",
      label: "Nombre del documento",
      type: "text",
      required: true,
    },
    {
      name: "description",
      label: "Descripción",
      type: "text",
      required: false,
    },
    {
      name: "documentKind",
      label: "Tipo de documento",
      type: "options",
      options: categories?.map(
        (category: { value: string; label: string }) => ({
          value: category.value,
          label: category.label,
        })
      ),
    },
  ];

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const payload: any = {
      ...currentDocument,
      fileName: data.fileName,
      description: data.description ?? "",
      documentKind: data.documentKind ?? "Sin definir",
    };

    const accessToken = await getAccessTokenSilently();

    try {
      setLoading(true);
      const res = await APIPostWithError(
        "/doc-ref/edit-document",
        accessToken,
        payload
      );

      if (res) {
        updateDocumentOnAtom(payload);
        resetCommonComponent();
        setCommonComponents({
          showSuccessModal: true,
          message: "Los datos fueron guardados con éxito",
        });
      } else {
        setAppError("Error al editar el documento");
      }
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  });

  return (
    <AndesModal
      handleClose={resetCommonComponent}
      message="Detalles del documento"
      handleSubmit={onSubmit}
      withScroll
    >
      <Box mt={2} width="100%">
        <AutomaticForm fields={fields} control={control} errors={errors} />
        <FormDisplay label="Formato" />
        {currentDocument?.dateCreated && (
          <FormDisplay
            label="Fecha de Creacion"
            defaultValue={format(Number(currentDocument?.dateCreated), "PPP", {
              locale: es,
            })}
          />
        )}
        <FormLabel label="Creado Por" isFocused={false} required={true} />
        {currentDocument && <CreatorField document={currentDocument} />}
      </Box>
    </AndesModal>
  );
};

export default DocumentDetailModal;
