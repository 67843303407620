import { Link } from "react-router-dom";
import Image from "../../uiComponents/Image/Image";
import { Box } from "@mui/material";
import { colorPallette } from "../../Common/theme";

import "./styles.css";
import CONSTANTS from "../../Common/constants";

export default function Footer() {
  return (
    <Box
      style={{ backgroundColor: colorPallette.darkest }}
      className="footer"
      id="footer"
    >
      <div className="footer-grid">
        <div className="footer-box-1">
          <img
            src={process.env.PUBLIC_URL + `/images/andesdocs.png`}
            className="footer-logo"
            alt="Andes Docs"
            title="Logo de la empresa"
          />
          <p>
            <i className="fas fa-map-marker-alt margin-r primary"></i>
            <span className="f-small">Buenos Aires, Argentina</span>
          </p>
          <p>
            <i className="fas fa-phone margin-r primary"></i>
            <span className="f-small">Tel / Whatsapp: +54 9 11 3107-3107</span>
          </p>
          <Image name={"legal-tech-love.png"} className="footer-legal-tech" />
        </div>
        <div className="footer-grid-2">
          <div className="footer-box-2">
            <Link className="footer-link" to={CONSTANTS.ROUTES.PRIVACY_POLICY}>
              Politica de privacidad
            </Link>
            <Link
              className="footer-link"
              to={CONSTANTS.ROUTES.TERMS_CONDITIONS}
            >
              Términos y condiciones
            </Link>
            <Link
              className="footer-link"
              to={CONSTANTS.ROUTES.LEGAL_DISCLAIMER}
            >
              Aviso Legal
            </Link>
            <Link className="footer-link" to={CONSTANTS.ROUTES.CONTACT}>
              Contacto
            </Link>
          </div>
          <div className="footer-box-3">
            <a
              className="footer-link"
              href="https://www.instagram.com/andesdocs/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              className="footer-link"
              href="https://twitter.com/andesdocs_"
              target="_blank"
              rel="noreferrer"
            >
              Twitter
            </a>
            <a
              className="footer-link"
              href="https://www.linkedin.com/company/andes-docs"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Linkedin
            </a>
            <a
              className="footer-link"
              href="https://www.youtube.com/channel/UC5OED6ncslubtyhp4r0N_1w"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Youtube
            </a>
          </div>
        </div>
      </div>
      <div className="footer-disclaimer-div">
        <Box className="footer-disclaimer" color={colorPallette.primary}>
          El uso de este sitio se encuentra sujeto a los Términos y Condiciones,
          Aviso Legal, Aviso de Asesoramiento Legal Opcional y Políticas de
          Privacidad. AndesDocs no es un estudio jurídico y no puede ofrecer, ni
          ofrece asesoramiento legal alguno. Andes Docs provee información y
          software y Ud. es responsable por el uso apropiado de este material.
          Todos los derechos se encuentran reservados.
        </Box>
      </div>
    </Box>
  );
}
