const CONSTANTS = {
  EMPTY_ANSWER: "____________________________",
  ROLES: {
    ADMIN: "admin",
    ADMIN_EDITOR: "admin-editor",
    USER: "user",
    USER_EDITOR: "user-editor",
  },
  ROUTES: {
    HOME: "/",
    DASHBOARD: "/dashboard",
    SUSCRIPTION: "/suscripcion",
    DOCX_EDITOR: "/docx-editor",
    DOCUMENT_SELECTOR: "/document-selector",
    COMPANY_DOCUMENT_CREATOR: "/company-document-creator",
    SERVICE_CONTRACT: "/contrato-de-servicios", // REVISAR SI ESTO SE USA
    RENT_CONTRACT: "/contrato-de-alquiler", // REVISAR
    EMPLOYMENT_CONTRACT: "/contrato-de-trabajo", // REVISAR
    MONEY_LOAN_AGREEMENT: "/contrato-de-prestamo-de-dinero", // REVISAR
    TEMPORARY_RENT_CONTRACT: "/contrato-de-alquiler-temporal", // REVISAR
    NDA: "/nda", //REVISAR
    COMODATO: "/comodato", //REVISAR
    PAGARE: "/pagare", // REVISAR
    LOCATION_RESERVATION: "/reserva-de-locacion", // REVISAR
    RECEIPT: "/recibo", // REVISAR
    OLD_LAW_RENT_CONTRACT: "/contrato-de-alquiler-vieja-ley", // REVISAR
    TEST1: "/test1", //REVISAR
    TEST2: "/test2", //REVISAR
    TEST3: "/test3", //REVISAR
    TEST4: "/test4", //REVISAR
    TEST5: "/test5", //REVISAR
    TEST6: "/test6", //REVISAR
    TEST7: "/test7", //REVISAR
    BAD: "/mal", // REVISAR
    PAYMENT: "/pago", //REVISAR
    DOWNLOAD_TEST: "/download-test", //REVISAR
    COMPANY_DOCUMENT_DOWNLOAD: "/company-document-download",
    REDIRECT: "/redirect", //REVISAR
    REDIRECT_ID: "/redirect/:id", //REVISAR
    PROFILE: "/profile",
    LOGIN_REDIRECT: "/login-redirect",
    EMAIL_VERIFICATION: "/email_verification",
    SUPPORT_CONFIG: "/support-config",
    PREV_USER_EXISTANT: "/prev-user-existant",
    ERROR: "/error", //REVISAR
    PAYMENT_ERROR: "/payment-error", // REVISAR
    SUBSCRIBE_CONTRACT: "/subscribe-contact", //REVISAR
    PRIVACY_POLICY: "/privacy-policy",
    TERMS_CONDITIONS: "/terms-and-conditions",
    LEGAL_DISCLAIMER: "/legal-disclaimer",
    PRODUCT_TOUR: "/productTour",
    CONTACT: "/contacto",
    DATA_DOWNLOADER: "/data-downloader", // REVISAR
    DATA_UPLOADER: "/data-uploader", // REVISAR
    DATA_UPDATER: "/data-updater", // REVISAR
    INTERNAL_GENERATOR: "/internal-generator",
    GENERATOR_FORM: "/generator-form",
    COMPANY_DOCUMENT_UPLOADER: "/backoffice-company-document-uploader",
    COMPANY_DOCUMENT_UPDATER: "/backoffice-company-document-updater",
    INTERNAL_DOCUMENT_GENERATOR: "/internal-document-generator",
    DOCUMENT_STARTER: "/document-starter",
  },
} as const;

export const categories: { label: string; value: string }[] = [
  { label: "Autorización de Venta", value: "Autorización de Venta" },
  { label: "Autorización de Locación", value: "Autorización de Locación" },
  { label: "Reserva de Venta", value: "Reserva de Venta" },
  { label: "Reserva de Locación", value: "Reserva de Locación" },
  { label: "Boleto Compraventa", value: "Boleto Compraventa" },
  { label: "Comodato", value: "Comodato" },
  { label: "Contrato de Locación", value: "Contrato de Locación" },
  { label: "Convenio de Desocupación", value: "Convenio de Desocupación" },
  {
    label: "Prórroga de Reserva de Venta",
    value: "Prórroga de Reserva de Venta",
  },
  { label: "Recibo de Reserva", value: "Recibo de Reserva" },
  {
    label: "Refuerzo de Reserva de Compra",
    value: "Refuerzo de Reserva de Compra",
  },
  {
    label: "Reintegro de Reserva de Compra",
    value: "Reintegro de Reserva de Compra",
  },
  { label: "Contrato de Seña", value: "Contrato de Seña" },
  { label: "Pagaré", value: "Pagaré" },
  { label: "Venta de Pasajes", value: "Venta de Pasajes" },
  { label: "Venta de Encomienda", value: "Venta de Encomienda" },
  { label: "Contraoferta", value: "Contraoferta" },
  { label: "Telegrama", value: "Telegrama" },
];

export default CONSTANTS;
