import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  TextRun,
} from "docx";
import { bresson_header } from "../docImages/bresson-header";

export const styles_bresson = {
  companyId: ["12427"],
  companyName: "Bresson",
  font: "Helvetica Neue",
  titleSize: 21,
  textSize: 21,
  lineSpacing: 320,
  marginRight: 780,
  marginLeft: 780,
  beforeParagraph: 100,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: bresson_header,
            transformation: {
              width: 330,
              height: 80,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "Bresson Brokers SA - CPI Federico Ayerza - Matrícula 2748 CUCICBA",
            size: 18,
            font: "Helvetica Neue",
          }),
        ],
      }),
      new Paragraph({
        spacing: {
          before: 60,
          after: 60,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "Av. Callao 1880, Ciudad de Buenos Aires - Tel: (5411) 5254-2020",
            size: 18,
            font: "Helvetica Neue",
          }),
        ],
      }),
      new Paragraph({
        spacing: {
          before: 0,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "inmobiliaria@bresson.com - www.bresson.com.ar",
            size: 18,
            font: "Helvetica Neue",
          }),
        ],
      }),
    ],
  }),
};
