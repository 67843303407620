import React from "react";
import { useAtomValue } from "jotai";
import { confirmationModalAtom } from "../../jotai/confimationModalAtom.jotai";
import {
  commonComponentAtom,
  loadingAtom,
} from "../../jotai/commonComponentAtom.jotai";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import ExpirationModal from "../../components/Modals/ExpirationModal/ExpirationModal";
import ErrorModal from "../../components/Modals/ErrorModal/ErrorModal";
import FolderNameModal from "../../components/Modals/FolderNameModal/FolderNameModal";
import SuccessModal from "../../components/Modals/SuccessModal/SuccessModal";
import FileUploadModal from "../../components/Modals/FileUploadModal/FileUploadModal";
import SignatureStatusModal from "../../components/Modals/SignatureStatusModal/SignatureStatusModal";
import SignerEmailModal from "../../components/Modals/SignerEmailModal/SignerEmailModal";
import DocumentDetailModal from "../../components/Modals/DocumentDetailModal/DocumentDetailModal";
import FolderModal from "../../components/Modals/FolderModal/FolderModal";
import NewFolderModal from "../../components/Modals/NewFolderModal/NewFolderModal";
import JotaiConfirmModal from "../../components/Modals/JotaiConfirmModal/JotaiConfirmModal";
import AlarmsModal from "../../components/Modals/AlarmsModal/AlarmsModal";
import EditProfileNameModal from "../../components/Modals/EditProfileNameModal/EditProfileNameModal";
import EditUserModal from "../../components/Modals/EditUserModal/EditUserModal";
import EditUserModalDeIn from "../../components/Modals/EditUserModal/EditUserModalDeIn";
import StatusModal from "../../components/Modals/StatusModal/StatusModal";
import AddNewSignerModal from "../../components/Modals/AddNewSignerModal/AddNewSignerModal";

const CommonCompoentRender: React.FC<any> = () => {
  const confirmationModalState = useAtomValue(confirmationModalAtom);
  const commonComponentState = useAtomValue(commonComponentAtom);
  const { showLoadingModal } = useAtomValue(loadingAtom);

  return (
    <div>
      {showLoadingModal && <LoadingModal />}
      {commonComponentState.showEditUserModal && <EditUserModal />}
      {commonComponentState.showEditUserModalDeIn && <EditUserModalDeIn />}
      {commonComponentState.showEditProfileNameModal && (
        <EditProfileNameModal />
      )}
      {commonComponentState.showStatusModal && <StatusModal />}
      {commonComponentState.showAlarmModal && <AlarmsModal />}
      {commonComponentState.showExpirationModal && <ExpirationModal />}
      {commonComponentState.showFolderNameModal && <FolderNameModal />}
      {commonComponentState.showFileUploadModal && <FileUploadModal />}
      {commonComponentState.showSignatureStatusModal && (
        <SignatureStatusModal />
      )}
      {commonComponentState.showSignerEmailModal && <SignerEmailModal />}
      {commonComponentState.showDocumentDetailModal && <DocumentDetailModal />}
      {commonComponentState.showFolderModal && <FolderModal />}
      {commonComponentState.showNewFolderModal && <NewFolderModal />}
      {commonComponentState.showAddNewSignerModal && <AddNewSignerModal />}
      {commonComponentState.showSuccessModal && <SuccessModal />}
      {commonComponentState.showErrorModal && <ErrorModal />}
      {confirmationModalState.show && <JotaiConfirmModal />}
    </div>
  );
};

export default CommonCompoentRender;
