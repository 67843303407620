import React, { useEffect, useState } from "react";
import DocumentCard from "../DocumentCard/DocumentCard";
import { APIPostWithError } from "../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../Utils/modalMethods";
import { useHistory } from "react-router-dom";
import { getAndSetSheetDataByCompanyId } from "../../../Utils/getSheetData";

import "./styles.css";
import { Box } from "@mui/material";
import CONSTANTS from "../../../Common/constants";

type CompanyDocument = {
  companyId: string;
  companyName: string;
  dataPath: string;
  documentId: string;
  documentName: string;
  documentName1: string;
  documentName2: string;
  generatorVersion: string;
  lastUpdated: string;
  removeLogo: boolean;
  documentType?: string;
};

type DashboardSelectorProps = {
  companyDocuments: CompanyDocument[] | null;
};

const DashboardSelector: React.FC<DashboardSelectorProps> = ({
  companyDocuments,
}) => {
  const [companyLogo, setCompanyLogo] = useState<string | null>(null);
  const { user, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [displayCompanyDocuments, setDisplayCompanyDocuments] = useState<
    any[] | null
  >(null);

  useEffect(() => {
    window.sessionStorage.removeItem("custom_document_name");
    setLoading(true);
    if (localStorage.getItem("currentCompanyLogo")) {
      setCompanyLogo(localStorage.getItem("currentCompanyLogo"));
    }

    if (companyDocuments) {
      const sortedDocuments = [...companyDocuments].sort((a, b) =>
        a.documentName1.localeCompare(b.documentName1)
      );
      setDisplayCompanyDocuments(sortedDocuments);
    }

    setLoading(false);
  }, [companyDocuments, user]);

  const getDocumentData = async (
    dataPath: string,
    generatorVersion: string,
    sheetId: string
  ) => {
    setLoading(true);
    try {
      // Fix back end and remove this if
      if (!generatorVersion) {
        generatorVersion = "1.0";
      }
      const accesToken = await getAccessTokenSilently();
      const doc = await APIPostWithError(
        "/company-docs/get-company-document",
        accesToken,
        { dataPath, generatorVersion }
      );
      if (generatorVersion && generatorVersion === "2.0") {
        if (doc) {
          localStorage.setItem(
            "generator-form-document",
            JSON.stringify(doc.document)
          );
          localStorage.setItem(
            "generator-form-data",
            JSON.stringify(doc.questions)
          );
          localStorage.setItem(
            "generator-form-sections",
            JSON.stringify(doc.sections)
          );
          localStorage.removeItem("generator-form-answers");
          if (sheetId) {
            const companyData = await getAndSetSheetDataByCompanyId(
              sheetId,
              accesToken
            );
            if (companyData)
              localStorage.setItem(
                "companyData",
                JSON.stringify({ ...companyData, type: "sheetsDataBase" })
              );
          }
          setLoading(false);
          history.push(CONSTANTS.ROUTES.GENERATOR_FORM);
        }
      } else {
        if (doc) {
          sessionStorage.setItem("lastDataPathForSave", dataPath);
          sessionStorage.setItem(
            "currentCompanyQuestionData",
            JSON.stringify(doc.questions)
          );
          sessionStorage.setItem(
            "currentCompanyDocumentData",
            JSON.stringify(doc.document)
          );

          if (window.sessionStorage.getItem("expirationDate")) {
            window.sessionStorage.removeItem("expirationDate");
          }
          setLoading(false);
          history.push(CONSTANTS.ROUTES.COMPANY_DOCUMENT_CREATOR);
        }
        if (!doc) {
          setAppError();
          setLoading(false);
        }
      }
    } catch {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="animate">
      {displayCompanyDocuments && (
        <div className="dashboard-select0r-wrapper">
          <p className="dashboard-message">
            <i className="fas fa-play secondary margin-r" /> Elegí el documento
            que quieras generar
          </p>
          <div className="dashboard-selector">
            <div className="dashboard-selector-grid">
              {displayCompanyDocuments &&
                displayCompanyDocuments.length > 0 &&
                displayCompanyDocuments.map((doc, index) => {
                  return (
                    <DocumentCard
                      key={index}
                      documentName={
                        doc.documentName || "Template de Andes Docs"
                      }
                      onClick={() => {
                        localStorage.setItem(
                          "document-update-data",
                          JSON.stringify(doc)
                        );

                        localStorage.setItem(
                          "removeLogo",
                          String(doc.removeLogo || false)
                        );

                        localStorage.setItem(
                          "selectedDocumentKind",
                          doc.documentType || "Sin definir"
                        );

                        getDocumentData(
                          doc.dataPath,
                          doc.generatorVersion,
                          doc.sheetId
                        );
                      }}
                      index={index}
                      logo={companyLogo}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </Box>
  );
};

export default DashboardSelector;
