import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  TextRun,
} from "docx";

import {
  kw_on_footer,
  kw_on_left_header,
  kw_on_logo,
} from "../docImages/kw-on";

export const styles_kw_on = {
  companyId: ["12423"],
  companyName: "Keller Williams ON",
  font: "Trebuchet MS",
  titleSize: 18,
  textSize: 20,
  lineSpacing: 470,
  marginRight: 780,
  marginLeft: 780,
  beforeParagraph: 200,

  header: new Header({
    children: [
      new Paragraph({
        alignment: AlignmentType.LEFT,
        spacing: { before: 0, after: 0 },
        children: [
          new ImageRun({
            data: kw_on_left_header,
            transformation: {
              width: 250,
              height: 70,
            },
          }),
          // Espacio adicional para mover la segunda imagen hacia la derecha
          new TextRun({
            text: "                                                                      ",
          }),
          new ImageRun({
            data: kw_on_logo,
            transformation: {
              width: 180,
              height: 75,
            },
          }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [
      new Paragraph({
        spacing: { before: 500, after: 0 },
        alignment: AlignmentType.CENTER,
        indent: { left: -300 },
        children: [
          new ImageRun({
            data: kw_on_footer,
            transformation: {
              width: 600,
              height: 50,
            },
          }),
        ],
      }),
    ],
  }),
};
