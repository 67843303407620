import { AlignmentType, Footer, Header, ImageRun, Paragraph } from "docx";
import { carta_documento_fondo } from "../docImages/szlit";

export const styles_szlit = {
  companyId: ["12393", "12348"],
  companyName: "Szlit",
  font: "Garamond",
  titleSize: 22,
  textSize: 16,
  lineSpacing: 250,
  marginRight: 900,
  marginLeft: 900,
  beforeParagraph: 200,

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          // new ImageRun({
          //   data: carta_documento_fondo,
          //   transformation: {
          //     width: 1200,
          //     height: 1300,
          //   },
          //   floating: {
          //     behindDocument: true,
          //     allowOverlap: true,
          //     horizontalPosition: {
          //       offset: 0,
          //     },
          //     verticalPosition: {
          //       offset: 0,
          //     },
          //   },
          // }),
        ],
      }),
    ],
  }),

  footer: new Footer({
    children: [],
  }),
};
